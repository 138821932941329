import config from '../app-config';
import {
    FETCH_TRANSLATIONS_SUCCESS
} from '../_constants/index';

export const fetchTranslations = (hotelId) => {
    return (dispatch) => {
        const requestOptions = {
            method: 'GET',
            headers: config.environment.headers
        }
    
        return fetch(`${config.environment.api_baseurl}/translation?hotel_id=${hotelId}`, requestOptions)
            .then(res => res.json())
            .then(response => {
                console.log("response", response);
                if(response) {
                    localStorage.setItem("translation",JSON.stringify(response.data));
                    localStorage.setItem("language","en");
                    dispatch(fetchTranslationsSuccess(response.data));
                }
            }).catch(error => {
                console.error("error", error);
            })
    }
}


function fetchTranslationsSuccess(data) { 
    return { 
        type: FETCH_TRANSLATIONS_SUCCESS,
        data 
    }
}