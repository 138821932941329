import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import guest from '../_reducers';
const loggerMiddleware = createLogger();
export const store = createStore(
    guest,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);


// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import reducer from '../_reducers';


// export const initStore = (initialState = {}) => {
//   return createStore(reducer, initialState, applyMiddleware(thunk));
// };