import React from "react";
import Layout from "../components/layout";
// import { withTranslation } from '../components/lang';
import { connect } from 'react-redux';
import LogoHotel from '../assets/images/logo.png';
import Logo from '../assets/images/logo.png';
import { guestActions } from '../_actions';
import htmlToImage from 'html-to-image';
// import Router from 'next/router';
import { guestConstants } from '../_constants';
import Loader from "../components/common/loader"
import Close from '../assets/images/close.svg';
import { t } from '../utils';
var QRCode = require('qrcode.react');

class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {imageCapture:"",printStatus:false,walletData:{},showMessage:false}
        this.captureScreen = this.captureScreen.bind(this);
        this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount(){ 
        localStorage.removeItem('reservation_code');
        localStorage.removeItem('reservationData');
        let walletData = (localStorage.getItem('reservationParams'))?
                        JSON.parse(localStorage.getItem('reservationParams'))
                        :"";
 
        if(document.getElementsByTagName("body")[0].getAttribute("class") === "android_device") {
            document.getElementsByTagName("body")[0].classList.remove("android_device");
        }
        if(walletData){
            this.setState({
                walletData:walletData
            })
        }else {
            // Router.push({
            //     pathname:"/booking"
            // })
            this.props.history.push("/booking");
        }
        
    }

    sendEmailConfirmation(){
        let params = this.state.walletData;        
        let propsT = this.props;
        propsT.guest.loading = true;
        let hotelDetail = (localStorage.getItem('hotelData'))?
        JSON.parse(localStorage.getItem('hotelData'))
        :"";
        let hotelDarkLogo = "";
        this.props.updateOnRefresh(true,guestConstants.LOADER);
        if (hotelDetail?.hotel_assets) {
            hotelDarkLogo = hotelDetail.hotel_assets.find(function (assets) {
                return (assets.asset_type == "hotel_logo_dark")
            });
        }
        params.hotel_logo = hotelDarkLogo?.file_path;
        params.hotel_name = hotelDetail.hotel_name;
        params.hotel_address = hotelDetail.hotel_address;
        htmlToImage.toJpeg(document.getElementById('qrcode'), { quality: 0.95 })
        .then((dataUrl) => {
            params.qr_code_image = dataUrl;
            this.props.sendConfirmationEmail(params);
            // var link = document.createElement('a');
            // link.download = 'QR-Code-Reservation.jpeg';
            // link.href = dataUrl;
            // link.click();
        });
        console.log(params,"params")
    }
    closePopup(){
        this.setState({
            showMessage:false
        })
    }
    captureScreen = () => {
        let status = false;
        // this.setState({printStatus:true})
        let propsT = this.props;
        propsT.guest.loading = true;
        this.props.updateOnRefresh(true,guestConstants.LOADER);
        let confirmation_code = (this.state.walletData.confirmation_code)?"-"+this.state.walletData.confirmation_code:"";
        let name = 'Thru-Reservation'+confirmation_code+'.jpeg';
        console.log(name,"namename")
        htmlToImage.toJpeg(document.getElementById('my-node'), { quality: 0.95 })
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = name;
            link.href = dataUrl;
            link.click();
        });
        setTimeout(() => {
            propsT.guest.loading = false;
            this.props.updateOnRefresh(false,guestConstants.LOADER);
        }, 2000);
    }
    render() {
        // let {t} = this.props;
        let guestDetail = {};
        if (this.props.guest.guestDetail) {
            guestDetail = this.props.guest.guestDetail;
            console.log(guestDetail,"guestDetail")
        }
        
        if(this.props.guest.sendEmailStatusCall=="success"){
            this.setState({
                showMessage:true
            })
            this.props.clearStorage(guestConstants.SEND_EMAIL_STATUS_CLEAR)
        }

        let hotelDetail = {};
		let hotelLightLogo = "";
		
		let hotelBackground = "";
		if (this.props.guest.hotelDetail) {
			hotelDetail = this.props.guest.hotelDetail;
			if (hotelDetail.hotel_assets) {
				hotelLightLogo = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_logo_light")
				});

				hotelBackground = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_background_image")
				});
			}
        }
        return (
            <React.Fragment>
				{(this.props.guest.loading ? <Loader /> : "")}
                <Layout is_outer location={this.props.location}>                
                <div className={`initial-screen-content-wrapper stop-loading`}>                    
                    <div className="initial-screen-content">
                        <div className="hotel-logo">
                            <img src={(hotelLightLogo) ? hotelLightLogo.file_path : LogoHotel} alt="hotel-logo" onError={(e) => {e.target.src = LogoHotel}}/>
                        </div>
                        
                        <div className="initial-screen-hotel-container">
                            <div className="wallet_wrapper"  id="my-node">
                            <div className="thru-logo-wallet">
                                    <img src={Logo} alt="thru-logo" />
                                </div>
                            <div className="wallet-complete-text">
                            <div>{t("wallet_complete_text_1")}</div>
                            <div> <span className="confirm_bold">{t("confirmation_code")}</span> <span className="primary_color">{(this.state.walletData.confirmation_code)?this.state.walletData.confirmation_code:""}</span>.
                            </div>
                            <div className="para_sanitize">{t("para_sanitize")}</div>
                                {/* <Trans i18nKey="wallet_complete_text">
                                   
                                </Trans> */}
                            </div>
                            <div className="qrcode" id="qrcode">                                
                                {/* {(this.state.walletData.confirmation_code)?
                                <div style={{background: "#fff", padding: "15px"}} id="qrcode">
                                    <QRCode value={this.state.walletData.confirmation_code}/> 
                                </div>
                                :""} */}
                            </div>
                            <div className="arrival-dept-info guest-2column">
                                <div className="guest-info-detail arrival-info">
                                <div className="guest-id">{t("last_name")}</div>
                                    <div className="guest-value">{this.state.walletData.last_name}</div>
                                </div>
                                <div className="guest-info-detail arrival-info">
                                    <div className="guest-id">{t("booking_id")}</div>
                                    <div className="guest-value">{this.state.walletData.external_booking_id}</div>
                                </div>
                            </div>

                            <div className="arrival-dept-info guest-2column">
                                <div className="guest-info-detail arrival-info">
                                    <div className="guest-id">{t('arrival')}</div>
                                    <div className="guest-value">{(this.state.walletData.arrival_date) ? this.state.walletData.arrival_date :'N/A'}</div>
                                </div>
                                <div className="guest-info-detail arrival-info">
                                    <div className="guest-id">{t('departure')}</div>
                                    <div className="guest-value">{(this.state.walletData.departure_date) ? this.state.walletData.departure_date : 'N/A'}</div>
                                </div>
                            </div>
                            
                            </div>
                            <div className="wallet-buttons-wrapper" align="center">
                                    {/* <button className="wallet-buttons apple">
                                        <div className="wallet"> <img src={WalletIcon} alt="wallet" /> </div>
                                        <div className="wallet-text">
                                            <Trans i18nKey="wallet_text_apple">
                                                <span className="addto">Add to</span>
                                                <span>Apple Wallet</span>
                                            </Trans>
                                        </div>
                                    </button> */}
                                   
                                    {/* <button className="wallet-buttons google">
                                        {t("Add to")} <span className="gpay">
                                        <img src={Google} alt="wallet" /></span> {t('pay')}
                                        
                                    </button> */}
                                {/* <img src={appleWallet} height={60}></img> */}
                            
                                <div className="save-link" onClick={this.captureScreen}>
                                    <a href="#" style={{color:'#1772ad'}}>{t('save_to_photo_library')}</a></div>
                                <div className="save-link pdf" onClick={this.sendEmailConfirmation}><a>{t('email_as_pdf')}</a></div>
                                
                                </div>
                        </div>
                    </div>
                </div>
                {(this.state.showMessage)?<div className="error-component-wrapper">
                    <div className="error-component-modal">
                        <div className="errorClose">
                            <span onClick={this.closePopup}>
                                <img src={Close} alt="close icon" />
                            </span>
                        </div>
                        <div className="error-heading">
                           {t("success")}
                        </div>
                        <div className="error-description">
                            {t("email_send_successfully")} 
                        </div>
                    </div>
                </div>:""}
            </Layout>
            </React.Fragment>
        );
    }
}

function mapState(state) {
    return state;
}

const actionCreators = {
    getGuestDetail: guestActions.getGuestDetail,
    sendConfirmationEmail:guestActions.sendConfirmationEmail,
    updateOnRefresh:guestActions.updateOnRefresh,
    clearStorage:guestActions.clearStorage
};

export default connect(mapState, actionCreators)((Wallet));