import { guestConstants } from '../_constants';
import { guestService } from '../_services';
import { fetchTranslations } from './translations';
import moment, { now } from 'moment';
export const guestActions = {
	getHotelDetail,
	getReservationDetail,
	updateFormData,
	getCountryData,
	saveGuestProfiles,
	updateOnRefresh,
	saveGuestIDImages,
	clearSaveGuestDoc,
	clearSaveGuestProfile,
	scanDocument,
	clearStorage,
	paymentSettlement,
	getPaymentDetail,
	intiatePayment,
	updatePaymentStatus,
	sendConfirmationEmail,
	getPaymentStatus,
	getLegalDocuments
};


function getCountryData() {
	return dispatch => {
		dispatch(request([]));
		guestService.getCountryStateCityData()
			.then(countryData => {
				if (countryData.status) {
					let data = countryData.data;
					console.log(data, "Contruy Dtat")
					let countryList = data.map(elm => ({ "id": elm.id, "text": elm.name, "phonecode": elm.phonecode }));
					localStorage.setItem('countryData', JSON.stringify(countryList));
					dispatch(success(countryList));
					// }
					// fs.writeFileSync(path.join(sourcedir, 'package-lock.json'), '{}', 'utf8');
					// underTest(sourcedir, {}, logger)
					// .then(result => {
					// 	expect(result).toEqual(sourcedir);
					// 	expect(fsUtil.isFile(path.join(sourcedir, 'package-lock.json'))).toBeFalsy();
					// })
					// .then(done, done.fail);
				} else {
					localStorage.setItem('countryData', []);
					dispatch(failure(countryData.message));
				}
			},
				error => {
					localStorage.setItem('countryData', []);
					dispatch(failure(error.toString()));
				});
	};


	function request(data) { return { type: guestConstants.GET_COUNTRY_DATA_REQUEST, data } }
	function success(data) { return { type: guestConstants.GET_COUNTRY_DATA_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_COUNTRY_DATA_FAILED, error } }
}

function getHotelDetail(params,resolve,reject) {
	return dispatch => {
		dispatch(request({}));
		if (params.hotel_code) {
			guestService.getHotelDetail(params)
				.then(
					hotelDetail => {
						if (hotelDetail.status) {
							if (hotelDetail.data) {
								if(hotelDetail?.data?.hotel_configs?.guest_detail_config?.visa){
									let visaDetail = hotelDetail.data.hotel_configs.guest_detail_config.visa;
									let count = Object.keys(visaDetail).length;
									let local = 0;
									let intl = 0;
									let i = 0;
									hotelDetail.data.show_visa_detail = false;
									Object.keys(visaDetail).map((key) => {
										if(visaDetail[key]?.local)
											local++
										
										if(visaDetail[key]?.intl)
											intl++
								
										i++;
										if(i === count){
											hotelDetail.data.show_visa_detail = {};
											hotelDetail.data.show_visa_detail.local = (local >= 1)?true:false;
											hotelDetail.data.show_visa_detail.intl = (intl >= 1)?true:false;
											localStorage.setItem('hotel_code', hotelDetail.data.hotel_code);
											localStorage.setItem('hotelData', JSON.stringify(hotelDetail.data));
											dispatch(success(hotelDetail.data));
										}
									})
									dispatch(fetchTranslations(hotelDetail.data.id)).then(()=>{
										resolve(true);
								 });	
									
								}
								
							} else {
								resolve(true);
								dispatch(success({}));
							}
						} else {
							reject(false);
							localStorage.setItem('hotel_code', "")
							dispatch(failure(hotelDetail.message));
						}
					},
					error => {
						reject(false);
						dispatch(failure(error.toString()));
					}
				);
		}
	};

	function request(data) { return { type: guestConstants.GET_HOTEL_DETAIL_REQUEST, data } }
	function success(data) { return { type: guestConstants.GET_HOTEL_DETAIL_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_HOTEL_DETAIL_FAILED, error } }
}

function getReservationDetail(params,resolve,reject) {
	console.log(params)
	return dispatch => {
		dispatch(request({}));
		if (params.reservation_code || (params.booking_id && params.last_name && params.arrival_date)) {
			guestService.getReservationDetail(params)
				.then(
					guestDetail => {
						if (guestDetail.status) {
							if (guestDetail.data) {
								let arrival_date_valid = 0;
								var end = moment(guestDetail.data.arrival_date).format("YYYY-MM-DD");
								var given = moment(end, "YYYY-MM-DD");
								var current = moment().startOf('day');
								arrival_date_valid = moment.duration(given.diff(current)).asDays() < -1;
								
								if(arrival_date_valid){
									guestDetail.data.guest_status = "expired";
								}
								localStorage.setItem('reservation_code', guestDetail.data.reservation_code);
								localStorage.setItem('reservationData', JSON.stringify(guestDetail.data));
								localStorage.setItem("anonymous_guest",true);
								let reservationParams = {};
								reservationParams.external_booking_id = guestDetail.data.external_booking_id;
								reservationParams.reservation_code = guestDetail.data.reservation_code;
								reservationParams.re_first_name = guestDetail.data.first_name;
								reservationParams.re_last_name = guestDetail.data.last_name;
								
								if(guestDetail.data.guests_profile.length>0) {
									reservationParams.first_name = guestDetail.data.guests_profile[0].first_name;
									reservationParams.last_name = guestDetail.data.guests_profile[0].last_name;
									reservationParams.email_address = guestDetail.data.guests_profile[0].email_address;
								} else {
									reservationParams.first_name = guestDetail.data.first_name;
									reservationParams.last_name = guestDetail.data.last_name;
									reservationParams.email_address = guestDetail.data.email_address;
								}
								
								reservationParams.arrival_date = guestDetail.data.arrival_date;
								reservationParams.departure_date = guestDetail.data.departure_date;
								reservationParams.confirmation_code = guestDetail.data.confirmation_code;
								reservationParams.room_rate = guestDetail.data.room_rate;
								reservationParams.currency_symbol = guestDetail.data.currency_symbol;
								reservationParams.phone_number = guestDetail.data.phone_number;
								reservationParams.deposit_amount = guestDetail.data.deposit_amount;
								reservationParams.guest_status = guestDetail.data.guest_status;
								localStorage.setItem('reservationParams', JSON.stringify(reservationParams));
								dispatch(success(guestDetail.data));
								resolve(true);
							} else {
								resolve(true);
								dispatch(success({}));
							}
						} else {
							reject(false);
							localStorage.removeItem('reservation_code')
							dispatch(failure(guestDetail.message));
						}
					},
					error => {
						reject(false);
						dispatch(failure(error.toString()));
					}
				);
		}
	};

	function request(data) { return { type: guestConstants.GET_GUEST_DETAIL_REQUEST, data } }
	function success(data) { return { type: guestConstants.GET_GUEST_DETAIL_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_GUEST_DETAIL_FAILED, error } }
}

function saveGuestProfiles(params,callback) {
	return dispatch => {
		dispatch(request([]));
		guestService.saveGuestProfiles(params)
			.then(
				response => {
					if (response.status) {
						localStorage.removeItem('reservation_code');
						localStorage.removeItem('reservation_code');
						dispatch(success(response));
						callback(true);
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.LOADER, data: true } }
	function success(data) { return { type: guestConstants.LOADER, data: true } }
	function failure(error) { return { type: guestConstants.LOADER, data: false } }
}

function updateOnRefresh(data, type) {
	const action = {
		type: type,
		data
	}
	return dispatch => {
		dispatch(action)
	}

}

function updateFormData(data) {
	return dispatch => {
		dispatch(request(data));
	};

	function request(formData) { return { type: guestConstants.UPDATE_FORMDATA, formData } }
}

function saveGuestIDImages(params,callback) {
	return dispatch => {
		dispatch(request([]));
		guestService.saveGuestIDImages(params)
			.then(
				response => {
					if (response.status) {
						console.log(response.data,"saveGuestIDImages")
						callback(response.data)				
						dispatch(success(response));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.SAVE_GUEST_ID_IMAGES_REQUEST, data } }
	function success(data) { return { type: guestConstants.SAVE_GUEST_ID_IMAGES_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.SAVE_GUEST_ID_IMAGES_FAILED, error } }
}


function clearSaveGuestDoc() {
	return dispatch => {
		dispatch(request([]));
	};

	function request(data) { return { type: guestConstants.SAVE_GUEST_ID_IMAGES_CLEAR, data } }
}


function clearSaveGuestProfile() {
	return dispatch => {
		dispatch(request([]));
	};

	function request(data) { return { type: guestConstants.SAVE_GUEST_PROFILE_CLEAR, data } }
}


function scanDocument(data) {
	return dispatch => {
		dispatch(request([]));
		guestService.scanDocument(data)
			.then(scanData => {
				if (scanData.status) {					
					dispatch(success(scanData.data));
				} else {
					dispatch(failure(scanData.message));
				}
			},
				error => {
					dispatch(failure(error.toString()));
				});
	};

	function request(data) { return { type: guestConstants.GET_OCR_DOCUMENT_REQUEST, data } }
	function success(data) { return { type: guestConstants.GET_OCR_DOCUMENT_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_OCR_DOCUMENT_FAILED, error } }
}

function clearStorage(type) {
	const action = {
		type: type,
		data:{}
	}
	return dispatch => {
		dispatch(action)
	}
}

function paymentSettlement(params,callback) {
	return dispatch => {
		dispatch(request([]));
		guestService.paymentSettlement(params)
			.then(
				response => {
					if (response.status) {
						let reservationParams =  JSON.parse(localStorage.getItem("reservationParams"));
						reservationParams.confirmation_code = (response.data&&response.data.hasOwnProperty("confirmation_code"))?response.data.confirmation_code:"";
						localStorage.setItem('reservationParams', JSON.stringify(reservationParams));
						
						dispatch(success(response));
						callback(true);
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.PAYMENT_SETTLEMENT_REQUEST, data } }
	function success(data) { return { type: guestConstants.PAYMENT_SETTLEMENT_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.PAYMENT_SETTLEMENT_FAILED, error } }
}

function getPaymentDetail(params) {
	
	return dispatch => {
		dispatch(request([]));
		guestService.getPaymentDetail(params)
			.then(
				response => {
					if (response.status) {						
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};
   
	function request(data) { return { type: guestConstants.PAYMENT_DETAIL_REQUEST, data} }
	function success(data) { return { type: guestConstants.PAYMENT_DETAIL_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.PAYMENT_DETAIL_FAILED, error } }
}

function intiatePayment(params) {
	return dispatch => {
		dispatch(request([]));
		guestService.intiatePayment(params)
			.then(
				response => {
					if (response.status) {						
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.INTIATE_PAYMENT_REQUEST, data} }
	function success(data) { return { type: guestConstants.INTIATE_PAYMENT_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.INTIATE_PAYMENT_FAILED, error } }
}

function updatePaymentStatus(params) {
	return dispatch => {
		dispatch(request([]));
		guestService.updatePaymentStatus(params)
			.then(
				response => {
					if (response.status) {	
						let reservationParams =  JSON.parse(localStorage.getItem("reservationParams"));
						if(reservationParams){
							reservationParams.confirmation_code = (response.data&&response.data.hasOwnProperty("confirmation_code"))?response.data.confirmation_code:"";
							localStorage.setItem('reservationParams', JSON.stringify(reservationParams));
						}												
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.UPDATE_PAYMENT_STATUS_REQUEST, data} }
	function success(data) { return { type: guestConstants.UPDATE_PAYMENT_STATUS_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.UPDATE_PAYMENT_STATUS_FAILED, error } }
}

function sendConfirmationEmail(params) {
	return dispatch => {
		dispatch(request([]));
		guestService.sendConfirmationEmail(params)
			.then(
				response => {
					if (response.status) {						
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.SEND_EMAIL_STATUS_REQUEST, data} }
	function success(data) { return { type: guestConstants.SEND_EMAIL_STATUS_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.SEND_EMAIL_STATUS_FAILED, error } }
}


function getPaymentStatus(params) {
	return dispatch => {
		dispatch(request({}));
		guestService.getPaymentStatus(params)
			.then(
				response => {
					if (response.status) {
						let reservationParams =  JSON.parse(localStorage.getItem("reservationParams"));
						if(reservationParams){
							reservationParams.confirmation_code = (response.data&&response.data.hasOwnProperty("confirmation_code"))?response.data.confirmation_code:"";
							localStorage.setItem('reservationParams', JSON.stringify(reservationParams));	
						}
								
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.GET_PAYMENT_STATUS_REQUEST, data} }
	function success(data) { return { type: guestConstants.GET_PAYMENT_STATUS_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_PAYMENT_STATUS_FAILED, error } }
}

function getLegalDocuments() {
	return dispatch => {
		dispatch(request({}));
		guestService.getLegalDocuments()
			.then(
				response => {
					if (response.status) {						
						dispatch(success(response.data));
					} else {
						dispatch(failure(response.message));
					}
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(data) { return { type: guestConstants.GET_LEGAL_DOCUMENT_REQUEST, data} }
	function success(data) { return { type: guestConstants.GET_LEGAL_DOCUMENT_SUCCESS, data } }
	function failure(error) { return { type: guestConstants.GET_LEGAL_DOCUMENT_FAILED, error } }
}