import React from "react";
import Select from "./selectW";
import Magic from '../assets/images/magic.svg';
import InputText from "./input";
// import { withTranslation } from './lang';
import Datepicker from './datepicker';
import { t } from '../utils';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileDocument: this.props.documentData
        };
        this.dateChange = this.dateChange.bind(this);
    }

    dateChange(e, date) {
        console.log("EEEe=", e, "   Value", date);
    }

    render() {
        // let { t } = this.props;
        let guestProfileConfig = {}
        let idDetailField = {}
        let visaDetailField = {}
        let travelField = {}
        let extraGuestInfo = [];
        let hotelDetail = {};
        let show_visa_detail = false;
        let travelDetailShow = {local:false,intl:false};
        let idDetailShow = {local:false,intl:false};
        if (this.props.hotelDetail) {
            hotelDetail = this.props.hotelDetail;
            show_visa_detail = hotelDetail.show_visa_detail;
            if (hotelDetail.hotel_configs && hotelDetail.hotel_configs.guest_detail_config) {
                if (this.props.primary) {
                    guestProfileConfig = (hotelDetail.hotel_configs.guest_detail_config.guest_profile.primary)?hotelDetail.hotel_configs.guest_detail_config.guest_profile.primary:{};
                    idDetailField = (hotelDetail.hotel_configs.guest_detail_config.id_card)?hotelDetail.hotel_configs.guest_detail_config.id_card:{};
                    visaDetailField = (hotelDetail.hotel_configs.guest_detail_config.visa)?hotelDetail.hotel_configs.guest_detail_config.visa:{};
                    travelField = (hotelDetail.hotel_configs.guest_detail_config.travel)?
                    hotelDetail.hotel_configs.guest_detail_config.travel:{};                    
                    extraGuestInfo = (hotelDetail.hotel_configs.guest_custom_fields.fields)?hotelDetail.hotel_configs.guest_custom_fields.fields:{};
                    let local = (Object.keys(travelField).find(key => travelField[key].local === true));
                    travelDetailShow.local = (local===undefined)? false:true;
                    
                    let  intl = Object.keys(travelField).find(key => travelField[key].intl === true);
                    travelDetailShow.intl = (intl===undefined)? false:true;

                    let idlocal = (Object.keys(idDetailField).find(key => idDetailField[key].local === true));
                    idDetailShow.local = (idlocal===undefined)? false:true;
                    
                    let  idintl = Object.keys(idDetailField).find(key => idDetailField[key].intl === true);
                    idDetailShow.intl = (idintl===undefined)? false:true;


                } else {
                    guestProfileConfig = (hotelDetail.hotel_configs.guest_detail_config.guest_profile.accompany)?hotelDetail.hotel_configs.guest_detail_config.guest_profile.accompany:{};
                    idDetailField = (hotelDetail.hotel_configs.guest_detail_config.id_card)?hotelDetail.hotel_configs.guest_detail_config.id_card:{};
                    visaDetailField = (hotelDetail.hotel_configs.guest_detail_config.visa)?hotelDetail.hotel_configs.guest_detail_config.visa:{};

                    let idlocal = (Object.keys(idDetailField).find(key => idDetailField[key].local === true));
                    idDetailShow.local = (idlocal===undefined)? false:true;
                    
                    let  idintl = Object.keys(idDetailField).find(key => idDetailField[key].intl === true);
                    idDetailShow.intl = (idintl===undefined)? false:true;
                }
            }
            console.log(travelField,"travelField")
        }
        let profile = {};
        let profileCustomData = {};
        let travelData = {};
        let documentIDData = {};
        let documentVisaData = {};
        if (this.props.profileData) {   
            console.log("====this.props.profileData==",this.props.profileData)
            profile = {...this.props.profileData};
        }

        if (this.props.documentData) {
            documentIDData = this.props.documentData.id_detail;
            documentVisaData = this.props.documentData.visa_detail;
        }

        if (this.props.profileCustomData) {
            profileCustomData = this.props.profileCustomData;
        }

        if (this.props.travelData) {
            travelData = this.props.travelData;
        }
        let countryCodeList = [];
        if (this.props.countryList) {
            this.props.countryList.map((data) => {
                let temp = {}
                if (data.phonecode > 0) {
                    temp.id = data.phonecode.toString();
                    temp.text = "+" + data.phonecode.toString();
                    countryCodeList.push(temp);
                }
            })
        }

        const selectProps = {
            options: this.props.countryList,
            getOptionLabel: (option) => (option.text ? option.text : option),
        };

        const getSelectedItem = () => {
            let item = "";
            item = this.props.countryList.find((opt) => {
                if (opt.id == profile.country_id)
                    return opt;
            })
            return item || "";

        }

        const isDisplayField = (dataArray, name, internationalGuest) => {
            if (dataArray[name] &&
                ((dataArray[name].intl == true && internationalGuest == true)
                    ||
                    (dataArray[name].local == true && internationalGuest == false))
            ) {
                return true;
            } else {
                return false;
            }
        }

        return (
            <div className="process-section-details">
                <div className="magic-text-wrapper">
                    <div className="magic-text-content">
                        <div className="magic-image">
                            <img width={24} src={Magic} alt="magic" />
                        </div>
                        <div className="magic-text">
                            <span>{t("magic_text_1")}</span>
                            <span>{t("magic_text_2")}</span>
                        </div>
                    </div>
                </div>

                {(Object.keys(guestProfileConfig).length > 0) ?
                    <div>
                        <div className="process-section-detail-subheader">
                            <h6>{t('reservation_details')}</h6>
                        </div>
                        <div className="guest-2column">
                            {isDisplayField(guestProfileConfig, 'first_name', this.props.internationalGuest) ?
                                <InputText
                                    name="first_name"
                                    placeholder={t('first_name')}
                                    onChange={this.props.updateProfle}
                                    value={(profile.first_name) ? profile.first_name : ''}
                                    // onBlur={this.props.validator.showMessageFor('first_name')}
                                    required={guestProfileConfig.first_name.required}
                                    helpText={(guestProfileConfig.first_name.required) ?
                                        this.props.validator.message(t('first_name'), profile.first_name, 'required')
                                        : ""}
                                    error={(guestProfileConfig.first_name.required) ?
                                        (this.props.validator.message(t('first_name'), profile.first_name, 'required')) ? true : false
                                        : ""}
                                />
                                : ""}

                            {isDisplayField(guestProfileConfig, 'last_name', this.props.internationalGuest) ?
                                <InputText name="last_name"
                                    placeholder={t('last_name')}
                                    onChange={this.props.updateProfle}
                                    value={(profile.last_name) ? profile.last_name : ''}
                                    // onBlur={this.props.validator.showMessageFor('last_name')}
                                    required={guestProfileConfig.last_name.required}
                                    helpText={(guestProfileConfig.last_name.required) ?
                                        this.props.validator.message(t('last_name'), profile.last_name, 'required')
                                        : ""}
                                    error={(guestProfileConfig.last_name.required) ?
                                        (this.props.validator.message(t('last_name'), profile.last_name, 'required')) ? true : false
                                        : ""}
                                />
                                : ""}
                        </div>                        
                        {isDisplayField(guestProfileConfig, 'email_address', this.props.internationalGuest) ?
                            <InputText                                
                                name="email_address" 
                                placeholder={t("email_address")}
                                onChange={this.props.updateProfle}
                                value={(profile.email_address) ? profile.email_address : ''}
                                required={guestProfileConfig.email_address.required}
                                //onBlur={() => this.props.validator.showMessageFor('email_address')}
                                helpText={this.props.validator.message(t("email_address"), profile.email_address,
                                    (guestProfileConfig.email_address.required) ? 'required|email' : 'email')}
                                error={(this.props.validator.message(t("email_address"), profile.email_address, (guestProfileConfig.email_address.required) ? 'required|email' : 'email')) ? true : false} />
                            : ""}

                        {isDisplayField(guestProfileConfig, 'phone_number', this.props.internationalGuest) ?
                            <div className="guest-2column">
                                <Select
                                    id={t('phone_country_code')}
                                    onChangeHandle={this.props.selectCountryCode}
                                    dataList={countryCodeList}
                                    name="phone_country_code"
                                    labelId="demo-simple-select-label"
                                    desabled={false}
                                    clear={false}
                                    scrollTopHeight="450"
                                    defaultValue={(profile.phone_country_code) ? profile.phone_country_code : ''}
                                    //onBlur={() => this.props.validator.showMessageFor('phone_country_code')}
                                    helpText={(guestProfileConfig.phone_number.required) ?
                                        this.props.validator.message(t('phone_country_code'), profile.phone_country_code, 'required')
                                        : ""}
                                    error={(guestProfileConfig.phone_number.required) ?
                                        (this.props.validator.message(t('phone_country_code'), profile.phone_country_code, 'required')) ? true : false
                                        : ""}
                                />
                                <InputText name="phone_number"
                                    placeholder={t('phone_number')}
                                    onChange={this.props.updateProfle}
                                    required={guestProfileConfig.phone_number.required}
                                    value={(profile.phone_number) ? profile.phone_number : ''}
                                    //onBlur={this.props.validator.showMessageFor('phone_number')}
                                    helpText={this.props.validator.message(t('phone_number'), profile.phone_number, (guestProfileConfig.phone_number.required) ? 'required|numeric|min:0|max:16' : 'numeric|min:0|max:16')}
                                    error={(this.props.validator.message(t('phone_number'), profile.phone_number, (guestProfileConfig.phone_number.required) ? 'required|numeric|min:0|max:16' : 'numeric|min:0|max:16')) ? true : false}
                                />
                            </div>
                            : ""}

                        {isDisplayField(guestProfileConfig, 'home_address', this.props.internationalGuest) ?
                            <InputText multiline name="home_address"
                                placeholder={t('address')}
                                onChange={this.props.updateProfle}
                                value={(profile.home_address) ? profile.home_address : ''}
                                required={guestProfileConfig.home_address.required}
                                //onBlur={this.props.validator.showMessageFor('home_address')}
                                helpText={(guestProfileConfig.home_address.required) ?
                                    this.props.validator.message(t('address'), profile.home_address, 'required|max:200')
                                    : ""}
                                error={(guestProfileConfig.home_address.required) ?
                                    (this.props.validator.message(t('address'), profile.home_address, 'required|max:200')) ? true : false
                                    : ""} />
                            : ""}

                        {isDisplayField(guestProfileConfig, 'country', this.props.internationalGuest) ?
                            <Select
                                id={t('country')}
                                onChangeHandle={this.props.selectCountryData}
                                dataList={this.props.countryList}
                                name="country"
                                labelId="demo-simple-select-label"
                                defaultValue={getSelectedItem}
                                scrollTopHeight="600"
                                //onBlur={this.props.validator.showMessageFor('country')}
                                helpText={(guestProfileConfig.country.required) ?
                                    this.props.validator.message(t('country'), profile.country_id, 'required')
                                    : ""}
                                error={(guestProfileConfig.country.required) ?
                                    (this.props.validator.message(t('country'), profile.country_id, 'required')) ? true : false
                                    : ""}
                                desabled={false}
                                clear={false}
                            />
                            : ""}

                        {isDisplayField(guestProfileConfig, 'loyalty_number', this.props.internationalGuest) ?
                            <InputText multiline name="loyalty_number"
                                placeholder={t('loyalty_number')}
                                onChange={this.props.updateProfle}
                                value={(profile.loyalty_number) ? profile.loyalty_number : ''}
                                required={guestProfileConfig.loyalty_number.required}
                                //onBlur={this.props.validator.showMessageFor('loyalty_number')}
                                helpText={(guestProfileConfig.loyalty_number.required) ?
                                    this.props.validator.message(t('loyalty_number'), profile.loyalty_number, 'required') : ""}
                                error={(guestProfileConfig.loyalty_number.required) ?
                                    (this.props.validator.message(t('loyalty_number'), profile.loyalty_number, 'required')) ? true : false
                                    : ""} />
                            : ""}
                    </div>
                    : ""}


                {(Object.keys(idDetailField).length > 0 && ((idDetailShow.local && !this.props.internationalGuest) || (this.props.internationalGuest && idDetailShow.intl))) ?
                    <div>
                        <div className="process-section-detail-subheader">
                            <h6>{t('id_details')}</h6>
                        </div>
                        {isDisplayField(idDetailField, 'docid_number', this.props.internationalGuest) ?
                            <InputText name="docid_number" placeholder={t('id_number')}
                                value={documentIDData.docid_number}
                                onChange={this.props.updateIDDocument}
                                required={idDetailField.docid_number.required}
                                //onBlur={this.props.validator.showMessageFor('id_number')}
                                helpText={(idDetailField.docid_number.required) ?
                                    this.props.validator.message('docid_number', documentIDData.docid_number, 'required',{messages: {required: t("please_enter_valid_id_number")}})
                                    : ""}
                                error={(idDetailField.docid_number.required) ?
                                    (this.props.validator.message('docid_number', documentIDData.docid_number, 'required',{messages: {required: t("please_enter_valid_id_number")}})) ? true : false
                                    : ""} />
                            : ""}

                        {isDisplayField(idDetailField, 'place_of_issue', this.props.internationalGuest) ?
                            <InputText name="place_of_issue" placeholder={t('place_of_issue')}
                                value={documentIDData.place_of_issue}
                                onChange={this.props.updateIDDocument} type="text"
                                //onBlur={this.props.validator.showMessageFor('place_of_issue')}
                                required={idDetailField.place_of_issue.required}
                                helpText={(idDetailField.place_of_issue.required) ?
                                    this.props.validator.message('place_of_issue', documentIDData.place_of_issue, 'required',{messages: {required: t("please_enter_place_of_issue")}})
                                    : ""}
                                error={(idDetailField.place_of_issue.required) ?
                                    (this.props.validator.message('place_of_issue', documentIDData.place_of_issue, 'required',{messages: {required: t("please_enter_place_of_issue")}})) ? true : false
                                    : ""} />
                            : ""}

                        <div className="guest-2column">
                            {isDisplayField(idDetailField, 'issue_date', this.props.internationalGuest) ?
                                <Datepicker name="issue_date"
                                    placeholder={t('issue_date')}
                                    
                                    value={(documentIDData.issue_date) ? documentIDData.issue_date : null}
                                    onChange={this.props.updateIDDocument}
                                    disableFuture = {true}
                                    //onBlur={this.props.validator.showMessageFor('issue_date')}
                                    helpText={(idDetailField.issue_date.required) ?
                                        this.props.validator.message('issue_date', documentIDData.issue_date, 'required',{messages: {required: t("please_enter_of_issue_date")}})
                                        : ""}
                                    error={(idDetailField.issue_date.required) ?
                                        (this.props.validator.message('issue_date', documentIDData.issue_date, 'required',{messages: {required: t("please_enter_of_issue_date")}})) ? true : false
                                        : ""}
                                />
                                : ""}

                            {isDisplayField(idDetailField, 'expiry_date', this.props.internationalGuest) ?
                                <Datepicker name="expiry_date"
                                    placeholder={t('expiry_date')}
                                    disablePast={true}
                                    value={(documentIDData.expiry_date) ? documentIDData.expiry_date : null}
                                    onChange={this.props.updateIDDocument}
                                    //onBlur={this.props.validator.showMessageFor('expiry_date')}
                                    helpText={(idDetailField.expiry_date.required) ?
                                        this.props.validator.message('expiry_date', documentIDData.expiry_date, 'required',{messages: {required: t("please_enter_of_expiry_date")}})
                                        : ""}
                                    error={(idDetailField.expiry_date.required) ?
                                        (this.props.validator.message('expiry_date', documentIDData.expiry_date, 'required',{messages: {required: t("please_enter_of_expiry_date")}})) ? true : false
                                        : ""}
                                />
                                : ""}
                        </div>
                    </div>
                    : ""}
                    
                {(Object.keys(visaDetailField).length > 0) && ((this.props.internationalGuest && hotelDetail.show_visa_detail.intl)|| (hotelDetail.show_visa_detail.local && !this.props.internationalGuest)) ?
                    <div>
                        <div className="process-section-detail-subheader">
                            <h6>{t('visa_details')}</h6>
                        </div>
                        {isDisplayField(visaDetailField, 'docid_number', this.props.internationalGuest) ?
                            <InputText name="docid_number" placeholder={t('id_number')}
                                value={documentVisaData.docid_number}
                                onChange={this.props.updateVisaDocument}
                                required={visaDetailField.docid_number.required}
                                //onBlur={this.props.validator.showMessageFor('docid_number')}
                                helpText={(visaDetailField.docid_number.required) ?
                                    this.props.validator.message('docid_number', documentVisaData.docid_number, 'required', {messages: {required: t("please_enter_valid_id_number")}})
                                    : ""}
                                error={(visaDetailField.docid_number.required) ?
                                    (this.props.validator.message('docid_number', documentVisaData.docid_number, 'required',{messages: {required: t("please_enter_valid_id_number")}})) ? true : false
                                    : ""} />
                            : ""}

                        {isDisplayField(visaDetailField, 'place_of_issue', this.props.internationalGuest) ?
                            <InputText name="place_of_issue" placeholder={t('place_of_issue')}
                                value={documentVisaData.place_of_issue}
                                onChange={this.props.updateVisaDocument} type="text"
                                //onBlur={this.props.validator.showMessageFor('place_of_issue')}
                                required={visaDetailField.place_of_issue.required}
                                helpText={(visaDetailField.place_of_issue.required) ?
                                    this.props.validator.message('place_of_issue', documentVisaData.place_of_issue, 'required',{messages: {required: t("please_enter_place_of_issue")}})
                                    : ""}
                                error={(visaDetailField.place_of_issue.required) ?
                                    (this.props.validator.message('place_of_issue', documentVisaData.place_of_issue, 'required',{messages: {required: t("please_enter_place_of_issue")}})) ? true : false
                                    : ""} />
                            : ""}

                        <div className="guest-2column">
                            {isDisplayField(visaDetailField, 'issue_date', this.props.internationalGuest) ?
                                <Datepicker name="issue_date"
                                    placeholder={t('issue_date')}
                                    value={(documentVisaData.issue_date) ? documentVisaData.issue_date : null}
                                    disableFuture = {true}
                                    onChange={this.props.updateVisaDocument}
                                    helpText={(visaDetailField.issue_date.required) ?
                                        this.props.validator.message('issue_date', documentVisaData.issue_date, 'required',{messages: {required: t("please_enter_of_issue_date")}})
                                        : ""}
                                    error={(visaDetailField.issue_date.required) ?
                                        (this.props.validator.message('issue_date', documentVisaData.issue_date, 'required'),{messages: {required:t("please_enter_of_issue_date")}}) ? true : false
                                        : ""}
                                />
                                : ""}

                            {isDisplayField(visaDetailField, 'expiry_date', this.props.internationalGuest) ?
                                <Datepicker name="expiry_date"
                                    placeholder={t('expiry_date')}
                                    disablePast={true}
                                    value={(documentVisaData.expiry_date) ? documentVisaData.expiry_date : null}
                                    onChange={this.props.updateVisaDocument}
                                    //onBlur={() => this.props.validator.showMessageFor('expiry_date')}
                                    helpText={(visaDetailField.expiry_date.required) ?
                                        this.props.validator.message('expiry_date', documentVisaData.expiry_date, 'required',{messages: {required: t("please_enter_of_expiry_date")}})
                                        : ""}
                                    error={(visaDetailField.expiry_date.required) ?
                                        (this.props.validator.message('expiry_date', documentVisaData.expiry_date, 'required',{messages: {required: t("please_enter_of_expiry_date")}})) ? true : false
                                        : ""}
                                />
                                : ""}
                        </div>
                    </div>
                    : ""}
                {(Object.keys(travelField).length > 0 && ((travelDetailShow.local && !this.props.internationalGuest) || (this.props.internationalGuest && travelDetailShow.intl))) ?
                <div className="process-section-detail-subheader">
                    <h6>{t('travel_detail')}</h6>
                </div>
                : ""}

                {(Object.keys(travelField).length > 0) ?
                    <React.Fragment>
                        {isDisplayField(travelField, 'arrival_date', this.props.internationalGuest) ?
                            <Datepicker name="arrival_date"
                                placeholder={t('arrival_date')}
                                disablePast={true}
                                value={(travelData.arrival_date) ? travelData.arrival_date : null}
                                onChange={this.props.updateTravel}
                                required={(travelField.arrival_date.required)}
                                helpText={(travelField.arrival_date.required) ?
                                    this.props.validator.message(t('arrival_date'), travelData.arrival_date, 'required') : ""}
                                error={(travelField.arrival_date.required) ?
                                    (this.props.validator.message(t('arrival_date'), travelData.arrival_date, 'required')) ? true : false
                                    : ""}
                                />
                            : ""}

                        {isDisplayField(travelField, 'departure_date', this.props.internationalGuest) ?
                            <Datepicker name="departure_date"
                                placeholder={t('departure_date')}
                                disablePast={true}
                                value={(travelData.departure_date) ? travelData.departure_date : null}
                                required={(travelField.departure_date.required)}
                                onChange={this.props.updateTravel}
                                minDate={travelData.arrival_date}
                                minDateMessage={t("departure_date_greater_then_arrival_date")}
                                helpText={(travelField.departure_date.required) ?
                                    this.props.validator.message(t('departure_date'), travelData.departure_date, 'required') : ""}
                                error={(travelField.departure_date.required) ?
                                    (this.props.validator.message(t('departure_date'), travelData.departure_date, 'required')) ? true : false
                                    : ""}
                                />
                            : ""}

                        {isDisplayField(travelField, 'purpose_of_visit', this.props.internationalGuest) ?
                            <InputText name="purpose_of_visit"
                                placeholder={t('purpose_of_visit')}
                                onChange={this.props.updateTravel}
                                value={(travelData.purpose_of_visit) ? travelData.purpose_of_visit : ''}
                                required={(travelField.purpose_of_visit.required)}
                                helpText={(travelField.purpose_of_visit.required) ?
                                    this.props.validator.message(t('purpose_of_visit'), travelData.purpose_of_visit, 'required') : ""}
                                error={(travelField.purpose_of_visit.required) ?
                                    (this.props.validator.message(t('purpose_of_visit'), travelData.purpose_of_visit, 'required')) ? true : false
                                    : ""} />
                            : ""}
                        {isDisplayField(travelField, 'country_of_origin', this.props.internationalGuest) ?
                            <InputText name="country_of_origin"
                                placeholder={t('country_of_origin')}
                                onChange={this.props.updateTravel}
                                value={(travelData.country_of_origin) ? travelData.country_of_origin : ''}
                                required={(travelField.country_of_origin.required)}
                                helpText={(travelField.country_of_origin.required) ?
                                    this.props.validator.message(t('country_of_origin'), travelData.country_of_origin, 'required') : ""}
                                error={(travelField.country_of_origin.required) ?
                                    (this.props.validator.message(t('country_of_origin'), travelData.country_of_origin, 'required')) ? true : false
                                    : ""} />
                            : ""}
                        {isDisplayField(travelField, 'city_of_origin', this.props.internationalGuest) ?
                            <InputText name="city_of_origin"
                                placeholder={t('city_of_origin')}
                                onChange={this.props.updateTravel}
                                required={(travelField.city_of_origin.required)}
                                value={(travelData.city_of_origin) ? travelData.city_of_origin : ''}
                                helpText={(travelField.city_of_origin.required) ?
                                    this.props.validator.message(t('city_of_origin'), travelData.city_of_origin, 'required') : ""}
                                error={(travelField.city_of_origin.required) ?
                                    (this.props.validator.message(t('city_of_origin'), travelData.city_of_origin, 'required')) ? true : false
                                    : ""} />
                            : ""}
                        
                    </React.Fragment>
                :""}   
                {(Object.keys(extraGuestInfo).length > 0) ?
                    <div className="process-section-detail-subheader">
                        <h6>{t('other_information')}</h6>
                    </div>
                    : ""}
                {(Object.keys(extraGuestInfo).map((key) => (
                    <React.Fragment>
                        {(extraGuestInfo[key].type == "date") ?

                            <Datepicker name={key}
                                placeholder={extraGuestInfo[key].name}
                                value={(profileCustomData[key]) ? profileCustomData[key] : null}
                                onChange={this.props.updateProfleCustom}
                                helpText={extraGuestInfo[key].required ? (this.props.validator.message(extraGuestInfo[key].name, profileCustomData[key], 'required')) : ""}
                                error={extraGuestInfo[key].required ? (this.props.validator.message(extraGuestInfo[key].name, profileCustomData[key], 'required')) ? true : false : ""}
                            />
                            :
                            <InputText name={key} placeholder={extraGuestInfo[key].name} type={extraGuestInfo[key].type} required={extraGuestInfo[key].required} onChange={this.props.updateProfleCustom}
                                value={(profileCustomData[key]) ? profileCustomData[key] : ''}
                                helpText={extraGuestInfo[key].required ? (this.props.validator.message(extraGuestInfo[key].name, profileCustomData[key], 'required')) : ""}
                                error={extraGuestInfo[key].required ? (this.props.validator.message(extraGuestInfo[key].name, profileCustomData[key], 'required')) ? true : false : ""} />  
                        }
                    </React.Fragment>

                )))}
            </div>
        );

    }
}
export default (Profile);