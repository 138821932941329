import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { t } from '../utils';



export default function ComposedTextField(props) {
    const [name, setName] = React.useState(props.value);
    const handleChange = (event) => {
        setName(event.target.value);
    };

    return (
        <div className="input-wrapper">
            {/* {!props.required ? <span className="optional">Optional</span>: ""} */}
            <FormControl>
            <InputLabel htmlFor="component-simple"><span>{props.placeholder}</span>{!props.required ? <span className="optional">{t("optional")}</span>: ""}</InputLabel>
                <Input   
                 name={props.name} 
                 onChange={props.onChange} 
                 value={props.value} 
                 type={props.type} 
                 required={props.required} 
                 error={props.error} 
                 onBlur={props.onBlur}  
                 autoComplete="off"/>
            </FormControl>
            {props.error && props.helpText?
                <div className="error-text">{props.helpText}</div>
                : ''}
        </div>
    );
}