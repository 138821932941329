import React from 'react';
import { Router } from "react-router-dom";
import { matchPath } from "react-router"
import $ from "jquery";
export function getStorage(key, type, defaultValue) {
  let data = localStorage.getItem(key);
  if (data) {
    switch (type) {
      case 'array':
      case 'object':
        try { data = JSON.parse(data) }
        catch (e) { data = defaultValue }
        break;
      case 'string':
        break;
      default:
        data = defaultValue;
        break;
    }
  } else {
    data = defaultValue;
  }
  return data;
}

export const t = textKey => {
    const langCode = (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
    var translationData = [];
    try { translationData = JSON.parse(localStorage.getItem('translation')) }
    catch (e) { translationData = [] }
    const langTrans = (translationData && translationData[langCode]) ? translationData[langCode] : {};
    return (langTrans && langTrans[textKey]) ? langTrans[textKey] : textKey;
}

export const t_with_params = (textKey,dataObj) => {
  const langCode = (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
  var translationData = [];
  try { translationData = JSON.parse(localStorage.getItem('translation')) }
  catch (e) { translationData = [] }
  const langTrans = (translationData && translationData[langCode]) ? translationData[langCode] : {};
  const enlangTrans = (translationData && translationData['en']) ? translationData['en'] : {};
  
  let entranText = (enlangTrans && enlangTrans[textKey]) ? enlangTrans[textKey] : textKey;
  
  let tranText = (langTrans && langTrans[textKey]) ? langTrans[textKey] : entranText;

  if(tranText){
    Object.keys(dataObj).map((key) => {
      tranText = tranText.replace(key,dataObj[key]);
      return null
    })
  }
  return tranText;
}

export const changeLanguage = langCode => {
    let language = langCode;
    localStorage.setItem('language', language);
    // this.setState({ language: language });
}
/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
export function multiSort(array, sortObject = {}) {
  console.log(array,"arrayarray")
  const sortKeys = Object.keys(sortObject);
  if(!array){
    return [];
  }
  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
      return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
      sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
  }

  const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;

      if (a === b) { // If the values are the same, do not switch positions.
          return 0;
      }

      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
  };

  return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
              const direction = sortObject[key];

              sorted = keySort(a[key], b[key], direction);
              index++;
          }
      }

      return sorted;
  });
}
export function currentLanguage() {
  return (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
} 


export const scrollToError = (className = "srv-validation-message", parentClass = "error-text") => {
  try {
    let errorElem = $('.' + className + ':first');
    console.log(errorElem,"srv-validation-message");
    if (errorElem.length) {
      let errorElements = document.getElementsByClassName('error-text');
        console.log("errorElements", errorElements);
        
        if (errorElements.length) {
          let scrolElemTop, amountToScroll;
          let errorElemRect = document.getElementsByClassName('error-text')[0].getBoundingClientRect();
          if (window.innerWidth > 640) {
            scrolElemTop = document.getElementsByClassName('reservation-detail')[0].scrollTop;
            amountToScroll = scrolElemTop + errorElemRect.top;
               document.getElementsByClassName('reservation-detail')[0].scrollTop = amountToScroll - 250;
          } else {
            let parentElem = errorElem.closest('.' + parentClass);    
            if (parentElem.length) {
              console.log(parentElem,"srv-validation-message");
              $('html, body').animate({
                scrollTop: parentElem.offset().top-120
              }, 'slow');
            }
          }
          
          document.querySelector('.error-text').parentElement.querySelector('input').focus();
        }
    }
  } catch (err) {
    console.log("Error in scrolling div", err);
  }
}


export function getLanguageData(object,defaultObject,key) {
  let keyData = {};
  
  keyData = object.find(function (data) {
    return (data.tag == key)
  })

  if(keyData?.text==undefined){
    keyData = defaultObject.find(function (data) {
      return (data.tag == key)
    })
  }
  return (keyData?.text)?keyData?.text:"";
}