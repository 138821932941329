import React from "react";
// import { withTranslation } from '../components/lang';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import queryString from 'query-string';
import { t } from '../utils';
class Expire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {redirect:""};
    }

    componentDidMount(){ 
    }
    
    render() {
        // let {t} = this.props; 
      return (
        <div className="main expire_page">
            <div className="error-component-wrapper">
              <div className="error-component-modal">
                  <div className="error-heading">
                      {t("link_has_expired")}
                  </div>
                  {/* <div className="error-description">
                      Please <a>Try Again</a>
                  </div> */}
              </div>
          </div>
        </div>
      );
    }
}

const actionCreators = {
};
export default connect(null,  actionCreators)((Expire));