import React from "react";
import $ from 'jquery';
import { t_with_params } from "../../utils";

class MultipleBtn extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!this.props.disabled) {
            if($(".container").hasClass("keyboard_up")) {
                $(".container").removeClass("keyboard_up");
            }
        }
        return nextProps != this.props;

    }

    render() {
        let current = this.props.currentStep + (this.props.currentStep < 3 && (this.props.subStep > 1) ?
        "." + (this.props.subStep - 1):"");
        let total = this.props.totalStep;
        return (
            <div className="footer-btn footer-2-btns">
                {/*Remove in case of specific theme */}
                <div className="footer-backdrop-default" />
                {/*Remove in case of specific theme*/}
                {((this.props.currentStep == 1 || this.props.currentStep == 4 ) ?
                    <button
                        className="secondary-button option-display"
                        onClick={this.props.secondaryBtn}>
                        <span>{this.props.secondaryBtnText}</span>
                        <span> <i className="arrow right"></i></span>
                    </button>
                    :
                    "")}
                <button
                    className={this.props.disabled ? 'primary-button option-display disabled' : 'primary-button option-display'}
                    disabled={this.props.disabled ? "disabled" : ""}
                    onClick={this.props.saveData}>
                    {(this.props.currentStep != 4) ?
                        <span>{t_with_params("step_current_of_total",{"{current}":current,"{total}":total})}</span>
                        :
                        ""}
                        {/* <span>{this.props.btnTitle}</span> */}
                        <span className={`${this.props.currentStep == 4 ? 'pay_multiple_btn': ""}`}>
                        <span>{this.props.btnTitle} </span>
                        <span><i className="arrow right"></i></span>
                        </span>
                        
                </button>
            </div>
        );
    }
}

export default MultipleBtn;