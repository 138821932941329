import React from 'react';
class PayUMoneyForm extends React.Component {
	constructor(props) {
    super(props);
  }

  submitForm = () => {
    document.getElementById("payumoney").submit();
  }
  render() {
    return (
      <form action={this.props.payuRequest.url} method='post' id="payumoney">
        <input type = "hidden" name="key" value={(this.props.payuRequest.key)?this.props.payuRequest.key:""}/>
        <input type = "hidden" name="hash" value={this.props.payuRequest.hash}/>
        <input type = "hidden" name="txnid" value={this.props.payuRequest.txnid}/>
        <input type = "hidden" name="surl" value={this.props.payuRequest.surl}/>
        <input type = "hidden" name="furl" value={this.props.payuRequest.furl}/>
        <input type = "hidden" name="amount" value={this.props.payuRequest.amount}/>
        <input type = "hidden" name="firstname" value={this.props.payuRequest.firstname}/>
        <input type = "hidden" name="email" value={this.props.payuRequest.email}/>
        <input type = "hidden" name="phone" value={this.props.payuRequest.phone}/> 
        <input type = "hidden" name="productinfo" value={this.props.payuRequest.productinfo}/> 
      </form>
    );
  }
}

export default PayUMoneyForm;