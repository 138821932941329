import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { t, t_with_params } from '../utils';

export default function DatePicker(props) {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    //

    const handleDateChange = (name,date) => {
        console.log(date,name)
        let e = {target:{name:name,value:date}}
        setSelectedDate(date);
        props.onChange(e)
    };
    // handleDateChange(e){

    // }
    return (
        <div className="datepicker-wrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                   /* disableToolbar
                    variant="inline"*/
                    format="yyyy-MM-dd"
                    margin="normal"
                    id={props.id}
                    disablePast={props.disablePast}
                    disableFuture={(props.disableFuture)?true:false}
                    label={props.placeholder}
                    value={props.value}
                    minDate = {props.minDate}
                    invalidDateMessage={t("date_should_be_in_YYYY_MM_DD_format")}
                    maxDateMessage={t_with_params("date_should_not_be_greater_then_attribute",{"{attribute}":props.placeholder})}
                    minDateMessage={(props.minDateMessage)?props.minDateMessage:t_with_params("date_should_not_be_lesser_then_attribute",{"{attribute}":props.placeholder})}
                    name={props.name}
                    allowKeyboardControl={false}
                    maskChar=""
                    onChange={(name,date)=>handleDateChange(props.name,date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            {props.error && props.helpText ?
                <div className="error-text">{props.helpText}</div>
                : ''}
        </div>
    );
};
