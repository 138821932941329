export const guestConstants = {
    GET_HOTEL_DETAIL_REQUEST:'GET_HOTEL_DETAIL_REQUEST',
    GET_HOTEL_DETAIL_SUCCESS:'GET_HOTEL_DETAIL_SUCCESS',
    GET_HOTEL_DETAIL_FAILED:'GET_HOTEL_DETAIL_FAILED',

    GET_GUEST_DETAIL_REQUEST:'GET_GUEST_DETAIL_REQUEST',
    GET_GUEST_DETAIL_SUCCESS:'GET_GUEST_DETAIL_SUCCESS',
    GET_GUEST_DETAIL_FAILED:'GET_GUEST_DETAIL_FAILED',

    GET_COUNTRY_DATA_REQUEST:'GET_COUNTRY_DATA_REQUEST',
    GET_COUNTRY_DATA_SUCCESS:'GET_COUNTRY_DATA_SUCCESS',
    GET_COUNTRY_DATA_FAILED:'GET_COUNTRY_DATA_FAILED',
    
    UPDATE_FORMDATA:'UPDATE_FORMDATA',

    LOADER:'LOADER',

    SAVE_GUEST_PROFILE_REQUEST:'SAVE_GUEST_PROFILE_REQUEST',
    SAVE_GUEST_PROFILE_SUCCESS:'SAVE_GUEST_PROFILE_SUCCESS',
    SAVE_GUEST_PROFILE_FAILED:'SAVE_GUEST_PROFILE_FAILED',
    SAVE_GUEST_PROFILE_CLEAR:'SAVE_GUEST_PROFILE_CLEAR',

    SAVE_GUEST_ID_IMAGES_REQUEST:'SAVE_GUEST_ID_IMAGES_REQUEST',
    SAVE_GUEST_ID_IMAGES_SUCCESS:'SAVE_GUEST_ID_IMAGES_SUCCESS',
    SAVE_GUEST_ID_IMAGES_FAILED:'SAVE_GUEST_ID_IMAGES_FAILED',
    SAVE_GUEST_ID_IMAGES_CLEAR:'SAVE_GUEST_ID_IMAGES_CLEAR',

    GET_BOOKING_DETAIL_REQUEST:'GET_BOOKING_DETAIL_REQUEST',
    GET_BOOKING_DETAIL_SUCCESS:'GET_BOOKING_DETAIL_SUCCESS',
    GET_BOOKING_DETAIL_FAILED:'GET_BOOKING_DETAIL_FAILED',
    GET_BOOKING_DETAIL_CLEAR:'GET_BOOKING_DETAIL_CLEAR',

    GET_RESERVATION_DATA_SUCCESS:'GET_RESERVATION_DATA_SUCCESS',


    GET_OCR_DOCUMENT_REQUEST:'GET_OCR_DOCUMENT_REQUEST',
    GET_OCR_DOCUMENT_SUCCESS:'GET_OCR_DOCUMENT_SUCCESS',
    GET_OCR_DOCUMENT_FAILED:'GET_OCR_DOCUMENT_FAILED',
    GET_OCR_DOCUMENT_CLEAR:'GET_OCR_DOCUMENT_CLEAR',

    
    PAYMENT_SETTLEMENT_REQUEST:'PAYMENT_SETTLEMENT_REQUEST',
    PAYMENT_SETTLEMENT_SUCCESS:'PAYMENT_SETTLEMENT_SUCCESS',
    PAYMENT_SETTLEMENT_FAILED:'PAYMENT_SETTLEMENT_FAILED',
    PAYMENT_SETTLEMENT_CLEAR:'PAYMENT_SETTLEMENT_CLEAR',

    PAYMENT_DETAIL_REQUEST:'PAYMENT_DETAIL_REQUEST',
    PAYMENT_DETAIL_SUCCESS:'PAYMENT_DETAIL_SUCCESS',
    PAYMENT_DETAIL_FAILED:'PAYMENT_DETAIL_FAILED',
    PAYMENT_DETAIL_CLEAR:'PAYMENT_DETAIL_CLEAR',

    INTIATE_PAYMENT_REQUEST:'INTIATE_PAYMENT_REQUEST',
    INTIATE_PAYMENT_SUCCESS:'INTIATE_PAYMENT_SUCCESS',
    INTIATE_PAYMENT_FAILED:'INTIATE_PAYMENT_FAILED',
    INTIATE_PAYMENT_CLEAR:'INTIATE_PAYMENT_CLEAR',

    UPDATE_PAYMENT_STATUS_REQUEST:'UPDATE_PAYMENT_STATUS_REQUEST',
    UPDATE_PAYMENT_STATUS_SUCCESS:'UPDATE_PAYMENT_STATUS_SUCCESS',
    UPDATE_PAYMENT_STATUS_FAILED:'UPDATE_PAYMENT_STATUS_FAILED',
    UPDATE_PAYMENT_STATUS_CLEAR:'UPDATE_PAYMENT_STATUS_CLEAR',

    SEND_EMAIL_STATUS_REQUEST:'SEND_EMAIL_STATUS_REQUEST',
    SEND_EMAIL_STATUS_SUCCESS:'SEND_EMAIL_STATUS_SUCCESS',
    SEND_EMAIL_STATUS_FAILED:'SEND_EMAIL_STATUS_FAILED',
    SEND_EMAIL_STATUS_CLEAR:'SEND_EMAIL_STATUS_CLEAR',


    GET_PAYMENT_STATUS_REQUEST:'GET_PAYMENT_STATUS_REQUEST',
    GET_PAYMENT_STATUS_SUCCESS:'GET_PAYMENT_STATUS_SUCCESS',
    GET_PAYMENT_STATUS_FAILED:'GET_PAYMENT_STATUS_FAILED',
    GET_PAYMENT_STATUS_CLEAR:'GET_PAYMENT_STATUS_CLEAR',

    GET_LEGAL_DOCUMENT_REQUEST:'GET_LEGAL_DOCUMENT_REQUEST',
    GET_LEGAL_DOCUMENT_SUCCESS:'GET_LEGAL_DOCUMENT_SUCCESS',
    GET_LEGAL_DOCUMENT_FAILED:'GET_LEGAL_DOCUMENT_FAILED',
    GET_LEGAL_DOCUMENT_CLEAR:'GET_LEGAL_DOCUMENT_CLEAR'
}