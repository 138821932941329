module.exports = { 
    "environment": {
      api_baseurl: "https://api.thru.app/guest",
      payment_api_baseurl: "https://api.thru.app/payment",
      api_key: "tiOJa0y9Ng9XSYs7bnGN95BWbpmkFBMUjgSkKiWc",
      headers: { 
        'Content-Type': 'application/json',
        'Accept':'application/json',
        'Accept-Language':'en',
        'X-API-Key':'tiOJa0y9Ng9XSYs7bnGN95BWbpmkFBMUjgSkKiWc'
      }
    }
  }
