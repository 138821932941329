import React, { Component } from 'react'
import SignaturePad from 'react-signature-canvas'
import { t,currentLanguage } from '../utils';
class Signature extends Component {
    constructor(props) {
        super(props);
    }
    state = {trimmedDataURL: null, showSign: true};
    sigPad = {};
    clear = () => {
        this.sigPad.clear();
        this.setState({showSign: true,trimmedDataURL:""})
        this.props.SignatureData("")
    };  
    onEndSign = () => {
        this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png')})
        this.props.SignatureData(this.sigPad.getTrimmedCanvas().toDataURL('image/png'))

    };
    onBeginSign = () => {
        this.setState({showSign: false})  
    };
    render () {
        let {trimmedDataURL} = this.state;
        return <div className="sign-wrapper">
            <div className="sigContainer" onClick={()=>{this.setState({showSign: false})  }}>
                <SignaturePad canvasProps={{className: 'sigPad'}} penColor="#5e636e"
                              onBegin={this.onBeginSign}
                              clearOnResize={false}
                              onEnd ={this.onEndSign}
                              backgroundColor="rgb(255,255,255)"
                              ref={(ref) => { this.sigPad = ref }} />
            </div>
            <span className={this.state.showSign ? 'sign-here': 'none-display'}>
                {t("sign_here")}
            </span>
            <div>
            {/* <button className="clear-btn" onClick={this.SaveSign}>
                    Save
                </button> */}

                <button className={!this.state.showSign ? 'clear-btn': 'none-display'}  onClick={this.clear}>
                    {t("clear")}
                </button>
               {/* <button className="clear-btn" onClick={this.trim}>
                    Trim
                </button> */}
            </div>
        </div>
    }
}
export default Signature;