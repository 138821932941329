import {
  FETCH_TRANSLATIONS_SUCCESS
} from '../_constants/index';

let initialState = {
  translations: {}
}

export function translations(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSLATIONS_SUCCESS:
      return {
        translations: action.data
      };
      break;
    default:
      return state
  }
}