import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SimpleSelect(props) {
    const classes = useStyles();
    // const [defaultValue,setDefaultValue]= null;
    const selectProps = {
        options: props.dataList,
        getOptionLabel: (option) => (option.text ? option.text : option),
    };

    const onOpenSelect = () => {
        var userAgent = navigator.userAgent.toLowerCase(); 
		var Android = userAgent.indexOf("android") > -1; 
		  
		if(Android) { 
            console.log('select onn open');
            if(!($(".container").hasClass('keyboard_up'))) {
                $(".container").addClass('keyboard_up');
            }
            setTimeout(() => {
                window.scrollTo(0,230)
            }, 500);
		}   
    } 
    const onCloseSelect = () => {
        if($(".container").hasClass("keyboard_up")) {
            $(".container").removeClass("keyboard_up");
        }
    } 
    

    const getSelectedItem = () => {
        let item="";
    if(props.dataList){
        if(props.name=="idType"){
           item = props.dataList.find((opt) => {
              if (opt.text == props.defaultValue)
                  return opt;
          })
        }else{
           item = props.dataList.find((opt) => {
              if (opt.id == props.defaultValue)
                  return opt;
          })
        }
        return item || "";  

    }

    }

    return (
        <div className="input-wrapper">
            <Autocomplete
                {...selectProps}
                id={props.id}
                onChange={(event, value) => props.onChangeHandle(event, value)}
                onBlur={props.onBlur}
                blurOnSelect={true}
                onOpen={()=>onOpenSelect()}
                onClose={()=>onCloseSelect()}
                disableClearable
                value={getSelectedItem()}
                disabled={props.desabled}
                renderInput={(params) =>
                    <TextField {...params}
                        label={props.id}
                        margin="normal" />}
            />
            {props.error && props.helpText ?
                <div className="error-text">{props.helpText}</div>
                : ''}
        </div>
    );
}