import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import $ from "jquery";

import GetStarted from "./pages/index";
import Booking from "./pages/booking";
import CheckinDetail from "./pages/checkindetail";
import Payment from "./pages/payment";
import Wallet from "./pages/wallet";
import Reservation from "./pages/reservation"; 
import Expire from "./pages/expire"
class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={GetStarted} />
            <Route exact path="/booking" component={Booking} />
            <Route exact path="/checkindetail" component={CheckinDetail} />
            <Route exact path="/payment" component={Payment} />
            <Route exact path="/wallet" component={Wallet} />
            <Route exact path="/reservation" component={Reservation} />
            <Route exact path="/expire" component={Expire} />
            <Route path="/*" component={() => <h1>404! Page Not Found</h1>} />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;
