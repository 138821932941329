import React from "react";
import LogoHotel from '../assets/images/logohotel1.svg';
import Logo from '../assets/images/logo.png';
import Shadow from '../assets/images/shadow.svg';
import Close from '../assets/images/close.svg';
import Layout from "../components/layout";
// import { withTranslation, Trans } from 'react-i18next';
// import  { withTranslation } from '../components/lang';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import queryString from 'query-string';
import {t, changeLanguage,currentLanguage, t_with_params, getLanguageData} from '../utils'
class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			setAnimation: true,
			showPrivacy: false,
			redirect:"",
			loader:true,
			currentLanguage:currentLanguage()
		};
		this.routeChange = this.routeChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		
		if(nextProps.guest.hotelDetail && nextProps.guest.guestDetail){
			console.log('componentWillReceiveProps', nextProps);
			setTimeout(() => {
				this.setState({
					setAnimation: false
				})
			}, 1000);
		}
		this.checkStatus(nextProps);
	}

	togglePrivacy = () => {
		this.setState(prevState => ({ showPrivacy: !prevState.showPrivacy }));
		window.scrollTo(0,0);
		if(!this.state.showPrivacy){
			document.getElementsByTagName("body")[0].classList.add("model_open");
		}else {
			document.getElementsByTagName("body")[0].classList.remove("model_open");
		}
	}

	checkStatus = (props) => {
		let guestDetail = props.guest.guestDetail;		
		switch (guestDetail.guest_status) {
			case "staying":
			case "checkin_completed_with_payment":
			case "checkin_completed_with_alert":
				this.setState({
					redirect:"/wallet"
				})
				return;
			case "cancelled":
			case "expired":
				this.setState({
					redirect:"/expire"
				})
				return;
			default:
				return;
		}
	}

	componentDidMount() {
		let params = queryString.parse(this.props.location.search);	
		this.props.getLegalDocuments();
		this.props.getCountryData();
		Promise.all([
			new Promise((resolve,rejact)=>this.props.getHotelDetail(params,resolve,rejact)),
			new Promise((resolve,rejact)=>this.props.getReservationDetail(params,resolve,rejact))
		]).then(()=>{
			setTimeout(()=>{
				this.setState({
					loader:false
				});
			},1000)
		}).catch(()=>{
			localStorage.removeItem('hotel_code');
			localStorage.removeItem("hotelData");
			localStorage.removeItem('reservation_code');
			localStorage.removeItem("reservationData");
			localStorage.removeItem("reservationParams");
			this.setState({
				redirect:"/booking"
			});
		});
		
		if (params.reservation_code === undefined || params.hotel_code === undefined) {
			localStorage.removeItem("reservation_code");
			localStorage.removeItem("hotel_code");
			this.setState({
				redirect:"/booking"
			})
			return;
		}
	}

	changeLang = (e) => {
		this.props.updateFormData(this.props)
		this.setState({
			currentLanguage:e.target.value
		})
		changeLanguage(e.target.value);
	};

	getLanguageId = (options, value) => {
		console.log(options, value);
		options.map((item, key) => {
			console.log('item--', item.value, value.toString());
			if (item.value === value.toString()) {
				return key
			}
		})
	};

	routeChange() {
		let path = `reservation`;
		this.props.history.push(path);
	}

	render() {
		let hotelDetail = {};
		let guestDetail = {};
		let welcomeText = "";
		let hotelName = "";
		let hotelAddress = "";
		let languageList = [
				{"lang_code":"en", "lang_name": "English"}
			];
		var hotelLogo = "";
		if (this.props.guest.guestDetail) {
			guestDetail = this.props.guest.guestDetail;
		}

		if (this.props.guest.hotelDetail) {
			hotelDetail = this.props.guest.hotelDetail;
			if (hotelDetail.hotel_assets) {
				hotelLogo = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_logo_light")
				});
			}

			if(hotelDetail.hotel_languages && hotelDetail.hotel_languages.length>0){
				languageList = hotelDetail.hotel_languages;
			}

			if (hotelDetail.hotel_locale) {
				let language = currentLanguage();
				let localeData = hotelDetail.hotel_locale[language];
				let enlocaleData = hotelDetail.hotel_locale["en"];
				if (localeData) {

					welcomeText = getLanguageData(localeData, enlocaleData, "welcome_text");
					
					hotelName = getLanguageData(localeData, enlocaleData, "hotel_name");

					hotelAddress = getLanguageData(localeData, enlocaleData, "hotel_address");

					
				}
			}
		}

		let privacyPolicy = "";
		if (this.props.guest.legalDocument && this.props.guest.legalDocumentCall==="success") {
			let privacyData = this.props.guest.legalDocument;		
			privacyPolicy = privacyData.find(function (data) {
				return (data.type === "privacy_policy");
			})
		}

		if (this.state.redirect) {
			this.props.history.push(this.state.redirect);
			// return <Redirect to={this.state.redirect} />
		}

		return (
			<div>
				<div className={`splash-screen-full ${this.state.setAnimation || this.state.loader ? '' : 'stop-loading'}`}>
				<div>
					<img className="ghost" src={Logo} alt="thru logo" />
					<p className="shadowFrame">
						<img src={Shadow} className="shadow"/>
					</p>
				</div>
			</div>
			{(!this.state.loader)?			
			<Layout
				location={this.props.location}
				is_outer
				booking_page={(Object.keys(hotelDetail).length > 0) ? false : true}>
				
				{(!this.state.setAnimation && Object.keys(hotelDetail).length > 0) ?
					<div className={`initial-screen-content-wrapper stop-loading`}>
						<div className="initial-screen-content">
							<div className="hotel-logo">
								<img src={(hotelLogo) ? hotelLogo.file_path : Logo} alt="hotel-logo" onError={(e) => {e.target.src = Logo}}/>
							</div>
							<div className="initial-screen-hotel-container">
								<div className="hotel-logo-header">
									{(languageList.length>1)?
										<div className="select-language">
										<span className="down-arrow-select"><i className="arrow down"></i></span>
										<select onChange={this.changeLang} value={this.state.currentLanguage}>
											{languageList.map((lang, i) =>
												<option value={lang.lang_code}>{lang.lang_name}</option>
											)}
										</select>
									</div>
									:""}
								</div>
								<div className="hotel-information">
									<div className="hotel-name">
										<span>{t('hotel_text')} <span>{hotelName?hotelName:''}</span></span>
									</div>
									<div className="hotel-address">
										<span>{hotelAddress?hotelAddress:""}</span><span>{hotelDetail.country_name}</span>
										<a href={`callto:${hotelDetail.phone_number}`}>{hotelDetail.phone_number}</a>
									</div>  
								</div>
								
								<div className="hotel-description">
								<div>
									<span>{t("dear")} {guestDetail.first_name+" "+guestDetail.last_name}, </span>
								</div>
									{(welcomeText) ? <span dangerouslySetInnerHTML={{ __html: welcomeText.split("\n").join("<br />") }}>
									</span> : ""}
								</div>
								<div className="hotel-get-started">
									<button className="primary-button" onClick={() => this.setState({redirect:'/reservation'})}>
										{t('get_started')}
									</button>
								</div>
								<div className="hotel-privacy-policy-container">
										<div>{t("privacy_policy_text_1")} 
											<a onClick={this.togglePrivacy}>&nbsp;{ t("privacy_policy")}</a>.
										</div>
									<div className="thru-logo">
										<img src={Logo} alt="thru logo" />
									</div>
								</div>
							</div>
						</div>
						{this.state.showPrivacy ?
							<div className="error-component-wrapper">
								<div className="error-component-modal privacy_modal">
									<div className="errorClose">
										<span onClick={this.togglePrivacy}>
											<img src={Close} alt="close icon" />
										</span>
									</div>
									<div className="error-heading">
										Privacy Policy
									</div>
										{	(privacyPolicy && privacyPolicy.content) ?<div className="error-description" dangerouslySetInnerHTML={{ __html:  privacyPolicy.content}}>										
									</div>
									:""}
								</div>
								</div>
							:
							''
							}
					</div>
							: ""
						}
			</Layout>
			:""} 
			</div>
		)
	}
}

function mapState(state) {
	return state;
}

const actionCreators = {
	getHotelDetail: guestActions.getHotelDetail,
	getReservationDetail: guestActions.getReservationDetail,
	updateFormData: guestActions.updateFormData,
	getCountryData: guestActions.getCountryData,
	getLegalDocuments: guestActions.getLegalDocuments
};
export default connect(mapState, actionCreators)((Index));