import React from "react";
import { Sticky, StickyContainer } from "react-sticky";
import IDScan from "../components/id-scan";
import Layout from "../components/layout";
import Profile from "../components/profile";
import Sign from "../components/sign";
import Pay from "../components/pay";
import PayUMoneyForm from "../components/payu";
import MultipleBtn from "../components/buttons/multiplebtn";
import SingleBtn from "../components/buttons/singlebtn";
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import Header from "../components/header";
import MediaQuery from 'react-responsive';
import SimpleReactValidator from 'simple-react-validator';
import Compress from "compress.js";
import { guestConstants } from '../_constants';
import Loader from "../components/common/loader";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { t, currentLanguage, multiSort,scrollToError,getStorage } from '../utils'
import moment from 'moment';

class checkinDetail extends React.Component {

	constructor(props) {
		super(props);
		let init = this.initialState();
		init.currentScanDocument = "";
		init.covid_question_ans = [];
		init.covid_question_valid = [];
		init.covidStatus = false;
		init.internationalGuest = false;
		this.state = init;
		this.saveStateData = this.saveStateData.bind(this);
		this.guestProfileInput = this.guestProfileInput.bind(this);
		this.guestIDDocumentInput = this.guestIDDocumentInput.bind(this);
		this.guestVisaDocumentInput = this.guestVisaDocumentInput.bind(this);
		this.selectGuestProfileDoc = this.selectGuestProfileDoc.bind(this);
		this.secondaryBtn = this.secondaryBtn.bind(this);
		this.guestProfileCustomInput = this.guestProfileCustomInput.bind(this);
		this.selectCountryData = this.selectCountryData.bind(this);
		this.selectCountryCode = this.selectCountryCode.bind(this);
		this.getCompressData = this.getCompressData.bind(this);
		this.getSignatureImage = this.getSignatureImage.bind(this);
		this.handlePreviousStep = this.handlePreviousStep.bind(this);
		this.updateScanData = this.updateScanData.bind(this);
		this.clearOcrScanFailed = this.clearOcrScanFailed.bind(this);
		this.covidAnswer = this.covidAnswer.bind(this);

		this.validator = new SimpleReactValidator({
			messages: {
				required: t("please_enter_your") + ' :attribute',
				numeric: t("please_enter_a_valid") + ' :attribute',
				email: t("please_enter_a_valid") + ' :attribute'
			},
			locale: currentLanguage()
		});

		this.payumoney = React.createRef();
	}

	initialState() {
		// let { t } = this.props;
		return {
			guest_profile: {},
			travel_detail: {},
			guest_document_images: [],
			guest_custom_field: {},
			ocrDataError: false,
			paymentButtonEnable: false,

			guest_document: {
				id_detail: {
					docid_number: "",
					docid_type: "",
					nationality: "",
					place_of_issue: "",
					expiry_date: null,
					issue_date: null,
					doc_image_front: "",
					doc_image_back: "",
					country_id: "",
					IdTypeId: ""

				},
				visa_detail: {
					visa_image: ""
				}
			},
			paymentIntiate: {},
			btnTitle: t("review_id"),
			selectNationality: false,
			selectIdType: false,
			scanDocument: false,
			forOnlyOnePerson: false,
			signValidation: true,
			wallet: false,
			editableStatus: false			
		}

	}

	componentDidMount() {
		this.props.updateOnRefresh(
			true,
			guestConstants.LOADER
		)

		document.getElementsByTagName("body")[0].classList.add("android_device");

		setTimeout(() => {

			let propsData = {...this.props};
			let guestDetail = propsData.guestDetail;
			let guestData = {};
			guestData = {...this.props};
			guestData.checkindetail.noOfGuest = 1;
			guestData.checkindetail.formSteps = 1;
			let guest_params = getStorage("reservationParams","object",{});
			this.props.updateFormData(guestData);
			this.initCovidQuestionAns();
			let guestProfiles = guestDetail.guests_profile;
			let hotel_detail_local =  getStorage("hotelData","object",{});
			let finalData = [];
			let anonymous_guest = (localStorage.getItem("anonymous_guest")==="true")?true:false;
			if(propsData?.guestProfile?.length<=0 && anonymous_guest===false) {
				guestProfiles = multiSort(guestProfiles,{guest_type:"desc"});
				guestProfiles.map((data, index) => {
					let temp = {
						guest_profile : {},
						guest_custom_details : {},
						guest_document_images:[],
						travel_detail:{}
					}
					delete data.guest_custom_details;
					delete data.guest_type;
					let phoneNoData = (data.phone_number)?parsePhoneNumberFromString(data.phone_number):"";
					data.phone_number =(phoneNoData?.nationalNumber) ? phoneNoData.nationalNumber : data.phone_number;
					data.phone_country_code = (phoneNoData?.countryCallingCode) ? "+" + phoneNoData.countryCallingCode : "";
					
					temp.guest_profile = data;
					finalData.push(temp);
				})
				console.log(finalData,"propsDatapropsData")
				guestData.guestProfile = finalData;		
			}

			if (guestData.guestProfile.length >= 1 && (Object.keys(guestData.guestProfile[0]).length !== 0)) {
				console.log(guestData.guestProfile,"guestData.guestProfile")
				let scanDocument = false;
				(guestData?.guestProfile[0]?.guest_document?.id_detail && Object.keys(guestData.guestProfile[0].guest_document.id_detail).length>0)?
					scanDocument = true:
					scanDocument = false;
				this.setState({
					guest_profile: guestData.guestProfile[0].guest_profile,
					guest_custom_field: guestData.guestProfile[0].guest_custom_details,
					guest_document: guestData.guestProfile[0].guest_document,
					editableStatus: true,
					selectNationality: true,
					selectIdType: true,
					scanDocument: scanDocument
				})

			} else {
				this.setState({
					editableStatus: true,
					selectNationality: true,
					selectIdType: true,
					scanDocument: false
				})
				if (Object.keys(guestDetail).length > 0) {
					let phoneNoData = parsePhoneNumberFromString(guestDetail.phone_number)
					let guestTemp = {
						first_name: guestDetail.first_name,
						last_name: guestDetail.last_name,
						phone_number: (phoneNoData?.nationalNumber) ? phoneNoData?.nationalNumber : guestDetail.phone_number,
						phone_country_code: (phoneNoData?.countryCallingCode) ? "+" + phoneNoData?.countryCallingCode : "",
						email_address: guestDetail.email_address
					}
					this.setState({
						guest_profile: guestTemp
					})
				}
			}

			let paymentConfig = {};
			if (hotel_detail_local.payment_config) {
				paymentConfig = hotel_detail_local.payment_config;
				if ((paymentConfig.name) && paymentConfig.name) {
					this.props.updateOnRefresh(
						true,
						guestConstants.LOADER
					)
					let params = {}
					params.reservation_code = guest_params.reservation_code;
					this.props.getPaymentStatus(params);
				} 
			}
			// let { t } = this.props;
			console.log(guest_params.guest_status,"guest_params.guest_status")
			switch (guest_params && guest_params.guest_status) {
				case "checkin_pending":
					propsData.checkindetail.formSteps = 1;
					this.props.updateFormData(propsData);
					return;
				case "profile_uploaded":
				case "profile_uploaded_with_alert":
				case "checkin_completed_without_payment":
					this.setState({
						btnTitle: t("complete_payment")
					})
					if (paymentConfig.name && guest_params.deposit_amount > 0) {
						propsData.loading = true;
						if (this.props.getPaymentStatusData && this.props.getPaymentStatusData.paid === true) {
							// Router.push("/wallet");
							let pa = {};
							pa.reservation_code = this.props.guestDetail.reservation_code;
							this.props.paymentSettlement(pa, () => {
								this.props.updateOnRefresh(
									true,
									guestConstants.LOADER
								)
							});
						} else if (this.props.getPaymentStatusData && this.props.getPaymentStatusData.paid === false) {
							this.setState({
								paymentButtonEnable: true
							})
							propsData.checkindetail.formSteps = 4;							
							this.props.updateFormData(propsData);
						} else {
							propsData.checkindetail.formSteps = 4;
							this.props.updateFormData(propsData);
						}
					} else if(!paymentConfig?.name && guest_params?.deposit_amount==0){
						let reservation_code = guest_params.reservation_code;
						let params = { reservation_code: reservation_code };
						this.props.paymentSettlement(params, () => {
							this.props.updateOnRefresh(
								false,
								guestConstants.LOADER
							)
						});
					}

					return;
				default:
					return;
			}
		})

	}

	getStickyDesktopStyle = (style) => {
		return {
			...style,
			top: '100px',
			left: '50%',
			zIndex: '9',
			width: '100%'
		}
	}

	getStickyStyle = (style) => {
		return {
			...style,
			top: '0',
			left: '0',
			zIndex: '9',
			width: '100%'
		}
	}

	saveStateData = async (steps) => {
		let guestData = this.props;
		// let { t } = this.props;
		let noOfGuest = guestData.checkindetail.noOfGuest;
		
		switch (steps) {

			case 1:
				window.scrollTo(0, 0);
				
				if (typeof guestData.guestProfile[noOfGuest - 1] === 'undefined' ||
					typeof guestData.guestProfile[noOfGuest - 1] === undefined) {					
					guestData.checkindetail.formSteps = 2;
					let guestID = {};
					guestData.guestProfile.push(guestID);
					guestData.guestProfile[noOfGuest - 1].guest_document = this.state.guest_document;
					this.props.updateFormData(guestData);
					let totalAdult = guestData.guestDetail.no_of_adult;
					let btntext = (totalAdult > noOfGuest) ? t("adult") + " " + (noOfGuest + 1) : t("sign");
					let international = (this.state.guest_document?.visa_detail?.visa_image) ? true : false;
				
					this.setState({
						btnTitle: btntext,
						selectNationality: false,
						selectIdType: false,
						scanDocument: false,
						editableStatus: false,
						internationalGuest:international					
					});
				} else {
					let international = (this.state.guest_document?.visa_detail?.visa_image) ? true : false;
					console.log(international,"international savedata else",this.state.guest_document)
					let totalAdult = guestData.guestDetail.no_of_adult;
					let btntext = (totalAdult > noOfGuest) ? t("adult") + " " + (noOfGuest + 1) : t("sign");
					this.setState({
						btnTitle: btntext,
						internationalGuest:international
					});
					guestData.guestProfile[noOfGuest - 1].guest_document = this.state.guest_document;
					guestData.checkindetail.formSteps = 2;
					this.props.updateFormData(guestData);
				}
				return;
			case 2:
				if (this.validator.allValid()) {
					window.scrollTo(0, 0);
					let resvationDetail = guestData.guestDetail;

					let guestTemp = this.state.guest_profile;
					if (noOfGuest > 1) {
						delete guestTemp.alert;
					}
					guestData.guestProfile[noOfGuest - 1].guest_profile = guestTemp;
					guestData.guestProfile[noOfGuest - 1].guest_document = this.state.guest_document;
					// if (this.state.guest_document_images.length > 0) {
						guestData.guestProfile[noOfGuest - 1].guest_document_images = this.state.guest_document_images;
					// }

					guestData.guestProfile[noOfGuest - 1].guest_custom_details = this.state.guest_custom_field;
					guestData.guestProfile[noOfGuest - 1].travel_detail = this.state.travel_detail;


					if ((guestData.guestProfile.length == resvationDetail.no_of_adult) && (guestData.guestProfile.length == noOfGuest)) {
						guestData.checkindetail.formSteps = 3;
						let payBtnText = "";
						if (guestData.hotelDetail.payment_config.name && guestData.guestDetail.deposit_amount > 0) {
							payBtnText = t("pay");
						} else {
							payBtnText = t("confirmation");
						}
						this.setState(this.initialState())
						this.setState({ btnTitle: payBtnText });
						this.props.updateFormData(guestData);

					} else {
						guestData.checkindetail.formSteps = 1;
						guestData.checkindetail.noOfGuest += 1;
						this.props.updateFormData(guestData);
						if (typeof guestData.guestProfile[noOfGuest] !== 'undefined' &&
							typeof guestData.guestProfile[noOfGuest] !== undefined &&
							(Object.keys(guestData.guestProfile[noOfGuest]).length !== 0)) {
							this.setState({
								guest_profile: guestData.guestProfile[noOfGuest].guest_profile,
								guest_custom_field: guestData.guestProfile[noOfGuest].guest_custom_details,
								guest_document: guestData.guestProfile[noOfGuest].guest_document,
								guest_document_images: guestData.guestProfile[noOfGuest].guest_document_images,
								btnTitle: t("review_id"),//t("adult") + guestData.checkindetail.noOfGuest,
								editableStatus: true,
								selectNationality: true,
								selectIdType: true,
								scanDocument: true
							})

						} else {
							this.setState(this.initialState())
							this.setState({ btnTitle: t("review_id") });
						}
						// 
					};
					this.validator.hideMessages();
				} else {
					console.log(this.validator, "this.validator")
					this.validator.showMessages();
					this.forceUpdate(scrollToError);
				}
				return;

			case 3:
				window.scrollTo(0, 0);
				let propsDetail = this.props;
				let guestProfile = propsDetail.guestProfile;
				let finalData = {};
				let guestDocument = [];
				finalData.reservation_code = propsDetail.guestDetail.reservation_code
				finalData.reservation_details = [];

				guestProfile.map((guest, index) => {
					if (guest.guest_document_images) {
						(guest.guest_document_images).map((data, key) => {
							if (data.content) {
								let imagesData = this.dataURLtoFile(data.content, data.name)
								guestDocument.push(imagesData);
							}
						})
					}
				})

				let documents = [];
				documents = await this.getCompressData(guestDocument);
				let finalImageData = {};
				finalImageData.reservation_code = propsDetail.guestDetail.reservation_code;
				finalImageData.hotel_id = propsDetail.hotelDetail.id;
				finalImageData.documents = documents;
				this.props.saveGuestIDImages(finalImageData, (data) => {
					this.saveGuestProfileData(data);
				});
				return;
			case 4:
				window.scrollTo(0, 0);
				/**
				 * Payment Gateway Conditions
				 */
				console.log(guestData.hotelDetail, "guestData.hotelDetail")
				if ((guestData.hotelDetail) && guestData.hotelDetail.payment_config) {
					let paymentConfig = guestData.hotelDetail.payment_config;
					if ((paymentConfig.name) && paymentConfig.name) {
						let params = {};
						params.reservation_code = guestData.guestDetail.reservation_code;
						params.payment_code = "";
						this.props.intiatePayment(params);						
					} else {
						console.log("not paymentConfig");
					}
				}
				return;
			default:
				return;
		}
	}

	guestProfileCustomInput(e) {
		let guest_custom_field = { ...this.state.guest_custom_field };
		guest_custom_field[e.target.name] = e.target.value;
		this.setState({
			guest_custom_field: guest_custom_field
		})
	}

	guestProfileInput(e) {
		let guest_profile = { ...this.state.guest_profile };
		console.log(e.target.value);
		guest_profile[e.target.name] = e.target.value;
		this.setState({
			guest_profile: guest_profile
		})
	}

	guestTravelInput = (e) => {
		let travel_detail = { ...this.state.travel_detail };
		travel_detail[e.target.name] = e.target.value;
		this.setState({
			travel_detail: travel_detail
		})
	}

	guestIDDocumentInput(e) {
		let documents = { ...this.state.guest_document };
		let id_detail = documents.id_detail;
		id_detail[e.target.name] = e.target.value;
		documents.id_detail = id_detail;
		this.setState({
			guest_document: documents
		})
	}

	guestVisaDocumentInput(e) {
		let documents = { ...this.state.guest_document };
		let visa_detail = documents.visa_detail;
		visa_detail[e.target.name] = e.target.value;
		documents.visa_detail = visa_detail;
		this.setState({
			guest_document: documents
		})
	}

	selectGuestProfileDoc(selectedObj, type, status) {

		let documents = { ...this.state.guest_document };
		let id_detail = (documents && documents.id_detail) ? documents.id_detail : {};
		let profileDocument = [...this.state.guest_document_images];
		let visa_detail = (documents?.visa_detail)?documents?.visa_detail:{};
		let hotelDetail = { ...this.props.hotelDetail };
		switch (type) {
			case 'scan_document':
				let checkindetail = { ...this.props.checkindetail };
				let guestDetail = { ...this.props.guestDetail };
				let tempType = id_detail['docid_type'];
				let noOfGuest = checkindetail.noOfGuest;
				let image_name = tempType + "-" + noOfGuest + ".png";
				let scanDocType = "";
				console.log("document Scan", selectedObj)

				if (selectedObj.idProofImageFront) {
					scanDocType = "id_detail";

					id_detail['doc_image_front'] = selectedObj.idProofImageFront;
					let imageFrontData = profileDocument.find(function (proImgage) {
						if (proImgage.name == "GuestID-Front-" + image_name)
							return true;
					});
					let imageFrontIndex = profileDocument.indexOf(imageFrontData);

					if (imageFrontIndex === -1) {
						profileDocument.push({ name: "GuestID-Front-" + image_name, content: selectedObj.idProofImageFront });
						console.log("imageFrontIndex", imageFrontIndex, profileDocument)
					} else {
						profileDocument[imageFrontIndex] = { name: "GuestID-Front-" + image_name, content: selectedObj.idProofImageFront };
					}

				}

				if (selectedObj.idProofImageBack) {
					scanDocType = "id_detail";
					id_detail['doc_image_back'] = selectedObj.idProofImageBack;
					let imageBackData = profileDocument.find(function (proImgage) {
						if (proImgage.name == "GuestID-Back-" + image_name)
							return true;
					});

					let imageBackIndex = profileDocument.indexOf(imageBackData);
					if (imageBackIndex === -1) {
						profileDocument.push({ name: "GuestID-Back-" + image_name, content: selectedObj.idProofImageBack });
					} else {
						profileDocument[imageBackIndex] = { name: "GuestID-Back-" + image_name, content: selectedObj.idProofImageBack };
					}

				}

				if (selectedObj.visaImage) {
					scanDocType = "visa_detail";
					visa_detail['visa_image'] = selectedObj.visaImage;

					let imageVisaData = profileDocument.find(function (proImgage) {
						if (proImgage.name == "GuestID-Visa-" + image_name)
							return true;
					});

					let imageVisaIndex = profileDocument.indexOf(imageVisaData);
					if (imageVisaIndex === -1) {
						profileDocument.push({ name: "GuestID-Visa-" + image_name, content: selectedObj.visaImage });
					} else {
						profileDocument[imageVisaIndex] = { name: "GuestID-Visa-" + image_name, content: selectedObj.visaImage };
					}
				}

				if (status) {
					documents.id_detail = id_detail;
					this.setState({
						scanDocument: true,
						guest_document_images: profileDocument,
						currentScanDocument: selectedObj.selectDocType
					})
				} else {
					documents.id_detail = id_detail;
					this.setState({
						scanDocument: false,
						guest_document_images: profileDocument,
						currentScanDocument: selectedObj.selectDocType
					})
				}
				console.log("scanDocType::", selectedObj.selectDocType)
				let scanDocumentParams = {};
				scanDocumentParams.reservation_code = guestDetail.reservation_code;
				scanDocumentParams.document_details = {};

				if (selectedObj.selectDocType === "id_detail" && selectedObj.idProofImageFront) {
					scanDocumentParams.document_details.front = selectedObj.idProofImageFront;
				}

				if (selectedObj.selectDocType === "id_detail" && selectedObj.idProofImageBack) {
					scanDocumentParams.document_details.back = selectedObj.idProofImageBack;
				}

				if (selectedObj.selectDocType === "visa_detail" && selectedObj.visaImage) {
					scanDocumentParams.document_details.front = selectedObj.visaImage;
				}

				if (Object.keys(scanDocumentParams.document_details).length > 0) {
					scanDocumentParams.type = selectedObj.selectDocType;
					this.props.scanDocument(scanDocumentParams);
				}

				break;
			case 'nationality':
				id_detail['nationality'] = selectedObj.text;
				id_detail['country_id'] = selectedObj.id;
				documents.id_detail = id_detail;
				console.log(selectedObj)
				documents.visa_detail = {};
				if ((selectedObj.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true)) {
					documents.visa_detail["nationality"] = selectedObj.text;					
				}

				console.log(selectedObj, "selectedObj-nationality")
				this.setState({
					selectNationality: true,
					internationalGuest: selectedObj.internationalGuest,
					guest_document: documents
				})
				break;
			case 'sacn_id':
				if (status) {
					id_detail['docid_type'] = selectedObj.text;
					documents.id_detail = id_detail;
					if ((selectedObj.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true)) {
						documents.visa_detail["docid_type"] = selectedObj.text;
					}
					this.setState({
						selectIdType: true,
						guest_document: documents
					})
				} else {
					id_detail['docid_type'] = "";
					documents.id_detail = id_detail;

					this.setState({
						selectIdType: false,
						guest_document: documents
					})
				}
				break;
		}
	}

	getSignatureImage(element) {
		if (element) {
			let propsDetail = this.props;
			let e_signature = propsDetail.guestProfile[0].guest_document_images.find((proImgage) => {
				if (proImgage.name == "e-signature")
					return true;
			});

			let e_signatureIndex = propsDetail.guestProfile[0].guest_document_images.indexOf(e_signature);
			if (e_signatureIndex === -1) {
				propsDetail.guestProfile[0].guest_document_images.push({ name: "e-signature", content: element })
			} else {
				propsDetail.guestProfile[0].guest_document_images[e_signatureIndex] = { name: "e-signature", content: element };
			}

			this.props.updateFormData(propsDetail);
			this.setState({ signValidation: false })
		} else {
			this.setState({ signValidation: true })
		}
	}

	secondaryBtn(step) {
		let guestDetail = this.props.guestDetail;
		if (step == 4) {
			console.log(guestDetail.confirmation_code, "guestDetail.confirmation_code")
			if (guestDetail.confirmation_code && guestDetail.confirmation_code != "") {
				this.setState({
					redirect: "/wallet"
				})
			} else {
				let reservation_code = guestDetail.reservation_code;
				let params = { reservation_code: reservation_code };
				this.props.paymentSettlement(params, () => {
					this.props.updateOnRefresh(
						false,
						guestConstants.LOADER
					)
				});
			}
		} else {
		//	this.props.checkindetail.formSteps = 3;
			let propsData = {...this.props}
			propsData.checkindetail.formSteps = 3;
			let guestPrimary = {...propsData.guestProfile[0]};
			delete propsData.guestProfile;
			propsData.guestProfile = Object.assign([]);
			propsData.guestProfile.push(guestPrimary);
			console.log(propsData,"updateFormData")
			this.props.updateFormData(propsData);
			let btntext = t('confirmation');
			let hotelDetail = this.props.hotelDetail;
			if (hotelDetail.payment_config.name && guestDetail.deposit_amount > 0) {
				btntext = t('pay');
			}
			this.setState({ forOnlyOnePerson: true, btnTitle: btntext })
		}
	}

	selectCountryData(event, selectedObj) {
		if (selectedObj != "" &&
			selectedObj != null &&
			selectedObj != undefined) {
			let guest_profile = { ...this.state.guest_profile };
			guest_profile['country_id'] = selectedObj.id;
			this.setState({
				guest_profile: guest_profile
			})
		}
	}

	selectCountryCode(event, selectedObj) {
		if (selectedObj != "" &&
			selectedObj != null &&
			selectedObj != undefined) {
			let guest_profile = { ...this.state.guest_profile };
			guest_profile['phone_country_code'] = selectedObj.id;
			this.setState({
				guest_profile: guest_profile
			})
		}
	}

	compressImage = files => {
		const compress = new Compress();
		return compress.compress(files, {
			size: 4, // the max size in MB, defaults to 2MB
			quality: this.compRate, // the quality of the image, max is 1,
			maxWidth: 1000, // the max width of the output image, defaults to 1920px
			mimeType: true,
			maxHeight: 1000, // the max height of the output image, defaults to 1920px
			resize: true, // defaults to true, set false if you do not want to resize the image width and height
		})
	}

	getCompressData = async (documents) => {
		let comp = await this.compressImage(documents);
		let finalData = [];
		comp.map((data, index) => {
			let temp = {};
			temp.name = data.alt;
			temp.content = data.prefix + data.data;
			finalData.push(temp)
		})

		return finalData;
	}

	dataURLtoFile(dataurl, filename) {

		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	saveGuestProfileData(documentData) {
		setTimeout(() => {
			let guestData = this.props;
			let propsDetail = this.props;
			let guestProfile = propsDetail.guestProfile;
			let finalData = {};
			finalData.reservation_code = propsDetail.guestDetail.reservation_code
			finalData.reservation_details = []
			//let documentData = this.props.saveGuestImagesStatus.data;

			finalData.reservation_code = propsDetail.guestDetail.reservation_code
			finalData.reservation_details = []

			guestProfile.map((guest, index) => {
				if(guest?.guest_profile?.phone_country_code && guest?.guest_profile?.phone_number){
					guest.guest_profile.phone_number = guest.guest_profile.phone_country_code + guest.guest_profile.phone_number;
				}else {
					guest.guest_profile.phone_number = "";
				}
				
				delete guest.guest_profile.phone_country_code;
				finalData.reservation_details[index] = guest.guest_profile;
				finalData.reservation_details[index].guest_custom_details = JSON.stringify(guest.guest_custom_details);
				finalData.reservation_details[index].travel_detail = guest.travel_detail;
				finalData.reservation_details[index].document_details = [];
				finalData.reservation_details[index].e_signature = "";
				if (index === 0) {
					finalData.reservation_details[index].declarations = JSON.stringify(this.state.covid_question_ans);
				}
				Object.keys(guest.guest_document).map((key, i) => {
					if (Object.keys(guest.guest_document[key]).length > 0) {
						let tempDoc = guest.guest_document[key];
						console.log(tempDoc, "tempDoc")
						let guestT = {};
						guestT.docid_number = tempDoc.docid_number;
						guestT.docid_type = tempDoc.docid_type;
						guestT.nationality = tempDoc.nationality;
						guestT.place_of_issue = tempDoc.place_of_issue;
						guestT.expiry_date = tempDoc.expiry_date;
						guestT.issue_date = tempDoc.issue_date;
						guestT.birth_date = tempDoc.birth_date;
						let i = 0;
						if (key == "visa_detail" && tempDoc.visa_image == "") {
							guestT = {};
						}
						documentData.map((doc) => {
							i++;
							if (tempDoc.hasOwnProperty("doc_image_front") && doc.name == "GuestID-Front-" + guestT.docid_type + "-" + (index + 1) + ".png") {
								guestT["docid_front_url"] = doc.document_url;
							}
							if (tempDoc.hasOwnProperty("doc_image_front") && doc.name == "GuestID-Back-" + guestT.docid_type + "-" + (index + 1) + ".png") {
								guestT["docid_back_url"] = doc.document_url;
							}
							console.log("Visha Card" + doc.name + "==" + "GuestID-Visa-" + guestT.docid_type + "-" + (index + 1) + ".png")
							if (tempDoc.hasOwnProperty("visa_image") && doc.name == "GuestID-Visa-" + guestT.docid_type + "-" + (index + 1) + ".png") {
								guestT.docid_type = "Visa";
								guestT["docid_front_url"] = doc.document_url;
							}
							if (doc.name == "e-signature") {
								finalData.reservation_details[index].e_signature = doc.document_url;
							}
						});
						if (Object.keys(guestT).length > 0) {
							finalData.reservation_details[index].document_details.push(guestT);
						}
					}
				});
			})
			console.log(finalData, "final data");
			let reservationParams = JSON.parse(localStorage.getItem("reservationParams"));
			reservationParams.first_name = finalData.reservation_details[0].first_name;
			reservationParams.last_name = finalData.reservation_details[0].last_name;
			if(finalData.reservation_details[0].email_address){
				reservationParams.email_address = (finalData.reservation_details[0].email_address);
			}

			localStorage.setItem("reservationParams", JSON.stringify(reservationParams));
			this.props.saveGuestProfiles(finalData, () => {
				this.completeSaveDataStep(propsDetail, guestData);
			});

		}, 1000);
	}

	completeSaveDataStep = (propsDetail, guestData) => {
		this.props.clearSaveGuestDoc();
		this.props.clearSaveGuestProfile();
		localStorage.removeItem('reservation_code');
		localStorage.removeItem('reservationData');
		let reservationParams = JSON.parse(localStorage.getItem("reservationParams"));
		reservationParams.guest_status = "profile_uploaded";
		localStorage.setItem("reservationParams", JSON.stringify(reservationParams));
		let params = {};
		params.reservation_code = propsDetail.guestDetail.reservation_code;

		let btntext = "";
		if (propsDetail.hotelDetail.payment_config.name && propsDetail.guestDetail.deposit_amount > 0) {
			btntext = t("complete_payment");
		} else {
			btntext = t("confirmation");
		}
		this.setState({
			guest_profile: {},
			guest_custom_field: {},
			guest_document: { id_detail: {}, visa_detail: {} },
			btnTitle: btntext
		});

		this.props.updateFormData(guestData);
		/**
		 * Payment Gateway Conditions
		 */

		if (propsDetail.hotelDetail.payment_config.name && propsDetail.guestDetail.deposit_amount > 0) {
			if (propsDetail.hotelDetail.payment_config) {
				let paymentConfig = propsDetail.hotelDetail.payment_config;

				if (paymentConfig && paymentConfig.name) {
					if (this.props.getPaymentStatusData && this.props.getPaymentStatusData.paid == true) {
						this.setState({
							redirect: "/wallet"
						})
					} else if ((this.props.getPaymentStatusData && this.props.getPaymentStatusData.paid == false)) {
						guestData.checkindetail.formSteps = 4;
						this.props.updateFormData(guestData);
						this.setState({
							paymentButtonEnable: true
						})
					} else {
						this.setState({
							paymentButtonEnable: false
						})
						guestData.checkindetail.formSteps = 4;
						this.props.updateFormData(guestData);
					}
				} else {
					this.setState({
						paymentButtonEnable: false
					})
					guestData.checkindetail.formSteps = 4;
					this.props.updateFormData(guestData);
				}
				this.props.updateOnRefresh(
					false,
					guestConstants.LOADER
				)
			}
		} else {
			this.props.paymentSettlement(params, () => {
				this.props.updateOnRefresh(
					false,
					guestConstants.LOADER
				)
			});
		}

	}

	handlePreviousStep(e, clickableStep) {

		let formDataVal = this.props;
		console.log(clickableStep, "clickableStep")
		// let { t } = this.props;

		this.props.checkindetail.formSteps = clickableStep;
		this.props.checkindetail.noOfGuest = 1
		let selectProfileStepData = {}, btnTitle = "";

		if (clickableStep === 1) {
			btnTitle = t("review_id");//t("adult") + 1;
		}

		if (clickableStep === 2) {
			let totalAdult = formDataVal.guestDetail.no_of_adult;
			btnTitle = (totalAdult > 1) ? t("adult") + " " + (2) : t("sign");
		}

		if (formDataVal.guestProfile.length >= 1 && (Object.keys(formDataVal.guestProfile[0]).length !== 0)) {
			//
			console.log(this.state.internationalGuest,"internationalGuestformDataVal.guestProfile[0]============", formDataVal.guestProfile[0])
			let guest_profile = {};
			if (formDataVal.guestProfile[0].guest_profile) {
				guest_profile = formDataVal.guestProfile[0].guest_profile;
			}else {
				let phoneNoData = parsePhoneNumberFromString(formDataVal.guestDetail.phone_number)
				let guestTemp = {
					first_name: formDataVal.guestDetail.first_name,
					last_name: formDataVal.guestDetail.last_name,
					phone_number: (phoneNoData?.nationalNumber) ? phoneNoData?.nationalNumber : formDataVal.guestDetail.phone_number,
					phone_country_code: (phoneNoData?.countryCallingCode) ? "+" + phoneNoData?.countryCallingCode : "",
					email_address: formDataVal.guestDetail.email_address
				}

				guest_profile = guestTemp;
			}
			this.setState({
				guest_profile: guest_profile,
				guest_custom_field: formDataVal.guestProfile[0].guest_custom_details,
				guest_document: formDataVal.guestProfile[0].guest_document,
				guest_document_images: formDataVal.guestProfile[0].guest_document_images,
				travel_detail: formDataVal.guestProfile[0].travel_detail,
				btnTitle: btnTitle,
				editableStatus: true,
				selectNationality: true,
				selectIdType: true,
				scanDocument: true
			})

		} else {
			
			
			this.setState({
				btnTitle: btnTitle,
				editableStatus: true,
				selectNationality: true,
				selectIdType: true,
				scanDocument: true
			})
		}
		this.props.updateFormData(this.props);
	}

	scanDocument(data) {
		this.props.scanDocument(data);
	}

	updateScanData() {
		let guest_document = { ...this.state.guest_document };
		let guest_profile = { ...this.state.guest_profile };
		let ocrData = (this.props.ocrDocument.document_details[0]) ? this.props.ocrDocument.document_details[0] : {};
		guest_profile.alert = false;
		if (this.state.currentScanDocument == "id_detail") {
			if (ocrData.document_type != undefined && guest_document.id_detail.docid_type.toLowerCase() != ocrData.document_type.toLowerCase()) {
				if ((ocrData.first_name == "" && ocrData.last_name == "") || ocrData.document_number == ""){
					guest_profile.alert = true;
				}
			}

			if ((ocrData.first_name != "" && ocrData.first_name !== undefined))
				guest_profile.first_name = ocrData.first_name;

			if (ocrData.last_name != "" && ocrData.last_name != undefined)
				guest_profile.last_name = ocrData.last_name;

			if (ocrData.address != "" && ocrData.address != undefined)
				guest_profile.home_address = ocrData.address;

			if (ocrData.document_number != "" && ocrData.document_number != undefined)
				guest_document.id_detail.docid_number = ocrData.document_number;

			if (ocrData.nationality != "" && ocrData.nationality != undefined)
				guest_document.id_detail.nationality = ocrData.nationality;

			if (ocrData.place_of_issue != "" && ocrData.place_of_issue != undefined)
				guest_document.id_detail.place_of_issue = ocrData.place_of_issue;

			if (ocrData.date_of_expiry != "" && ocrData.date_of_expiry != undefined)
				guest_document.id_detail.expiry_date = ocrData.date_of_expiry;

			if (ocrData.date_of_issue != "" && ocrData.date_of_issue != undefined)
				guest_document.id_detail.issue_date = ocrData.date_of_issue;

			if (ocrData.date_of_birth != "" && ocrData.date_of_birth != undefined)
				guest_document.id_detail.birth_date = ocrData.date_of_birth;

		} else if (this.state.currentScanDocument == "visa_detail") {

			if (ocrData.document_type != undefined && 'visa' != ocrData.document_type.toLowerCase()) {
				if ((ocrData.first_name == "" && ocrData.last_name == "") || ocrData.document_number == ""){
					guest_profile.alert = true;
				}
				console.log("visa_detail", guest_profile.alert)
			}
			if (ocrData.document_number != "" && ocrData.document_number != undefined)
				guest_document.visa_detail.docid_number = ocrData.document_number;

			if (ocrData.nationality != "" && ocrData.nationality != undefined)
				guest_document.visa_detail.nationality = ocrData.nationality;

			if (ocrData.place_of_issue != "" && ocrData.place_of_issue != undefined)
				guest_document.visa_detail.place_of_issue = ocrData.place_of_issue;

			if (ocrData.date_of_expiry != "" && ocrData.date_of_expiry != undefined)
				guest_document.visa_detail.expiry_date = ocrData.date_of_expiry;

			if (ocrData.date_of_issue != "" && ocrData.date_of_issue != undefined)
				guest_document.visa_detail.issue_date = ocrData.date_of_issue;

			if (ocrData.date_of_birth != "" && ocrData.date_of_birth != undefined)
				guest_document.visa_detail.birth_date = ocrData.date_of_birth;
		}

		this.setState({
			guest_document: guest_document,
			guest_profile: guest_profile
		});
		this.props.clearStorage(guestConstants.GET_OCR_DOCUMENT_CLEAR);
	}

	clearOcrScanFailed() {
		this.setState({
			currentScanDocument: "",
			ocrDataError: false
		})
	}

	paymentEnable = () => {
		this.setState({
			paymentButtonEnable: true
		})
	}

	covidAnswer(index, answer) {
		let covid_question_ans = [...this.state.covid_question_ans];
		covid_question_ans[index].answer = answer;
		let awsList = (covid_question_ans.filter(({ answer }) => answer !== "")).length
		console.log(covid_question_ans, "covid_question_ans", answer, index)

		let validStatus = false;

		if (awsList == covid_question_ans.length) {
			validStatus = true;
		}
		this.setState({
			covid_question_ans,
			covidStatus: validStatus
		});
	}

	initCovidQuestionAns = () => {
		let covid_declarations = {};
		let hotelDetail = { ...this.props.hotelDetail };
		if (hotelDetail.hotel_locale) {
			let language = "";
			language = currentLanguage();
			console.log(currentLanguage(), "languageinitCovidQuestionAns")
			let localeData = hotelDetail.hotel_locale[language];

			if (localeData) {
				covid_declarations = localeData.find(function (data) {
					return (data.tag == "covid_declarations");
				})
			}

		}
		let covid_data = []
		try {
			covid_data = (JSON.parse(covid_declarations.text) ? JSON.parse(covid_declarations.text) : []);
		} catch (e) {

		}

		let covid_question_ans = [];
		if (covid_data.length > 0) {
			covid_data.map((data, index) => {
				console.log(data, "datatatatat")
				let temp = {};
				temp.question = data;
				temp.answer = "";
				covid_question_ans.push(temp);
				console.log(index + 1 + "==" + covid_data.length)
				if (index + 1 == covid_data.length) {
					this.setState({
						covid_question_ans
					})
				}
			})
		} else {
			this.setState({
				covidStatus: true
			})
		}
	}

	documentChangeUpdate = (callback) => {
		let anonymous_guest = getStorage("anonymous_guest","string","");
		let reservationData = getStorage("reservationData","object",{});
		console.log(reservationData,"reservationData",anonymous_guest,"anonymous_guest")
		let guestNO = this.props.checkindetail.noOfGuest;
		let data = {};
		if(Object.keys(reservationData).length>0){		
			if(anonymous_guest=="false"){
				data = (reservationData?.guests_profile[guestNO-1])?reservationData?.guests_profile[guestNO-1]:{};	
				delete data.guest_custom_details;
				delete data.guest_type;
				let phoneNoData = (data.phone_number)?parsePhoneNumberFromString(data.phone_number):"";
				data.phone_number =(phoneNoData?.nationalNumber) ? phoneNoData.nationalNumber : data.phone_number;
				data.phone_country_code = (phoneNoData?.countryCallingCode) ? "+" + phoneNoData.countryCallingCode : "";

				if((guestNO-1)==0){
					let reservationParams = JSON.parse(localStorage.getItem("reservationParams"));
					if(data?.email_address){
						reservationParams.email_address = data.email_address;
					}
				}
				console.log(this.state,"prev statedta")
				
				
			}else {
				if(guestNO==1){
					
					let phoneNoData = parsePhoneNumberFromString(reservationData.phone_number)
					data = {
						first_name: reservationData.first_name,
						last_name: reservationData.last_name,
						phone_number: (phoneNoData?.nationalNumber) ? phoneNoData?.nationalNumber : reservationData.phone_number,
						phone_country_code: (phoneNoData?.countryCallingCode) ? "+" + phoneNoData?.countryCallingCode : "",
						email_address: reservationData.email_address
					}
				}
			}

			this.setState({
				guest_profile : data,
				guest_custom_details : {},
				guest_document_images:[],
				guest_document:{ id_detail: {}, visa_detail: {} },
				travel_detail:{}
			},function (){
				callback(true);
			});
		}
	}

	render() {
		let formData = {};
		let guestDetail = {};
		let countryList = [];

		let payuRequest = {};
		let anonymous_guest = (localStorage.getItem("anonymous_guest")==="true")?true:false;
		let hotel_params = getStorage("hotelData","object",{});
		let reservationParams = getStorage("reservationParams","object",{});
		if (this.props.countryData) {
			countryList = this.props.countryData;
		}

		if (this.props.checkindetail) {
			console.log(formData, "formData")
			formData = this.props.checkindetail;
		}

		if (this.props.ocrDocument && this.props.ocrDocumentCall == "success") {
			this.updateScanData();
		}

		if (this.props.ocrDocumentCall == "failed") {
			this.props.clearStorage(guestConstants.GET_OCR_DOCUMENT_CLEAR);
		}

		if (this.props.guestDetail) {
			guestDetail = this.props.guestDetail;
		}

		if (this.props.saveGuestImagesStatusCall == "success" && this.props.saveGuestImagesStatus.data) {
			this.props.clearStorage(guestConstants.SAVE_GUEST_ID_IMAGES_CLEAR);
		}

		if (this.props.paymentSettleCall == "success") {
			this.props.updateOnRefresh(
				true,
				guestConstants.LOADER
			)
			this.props.history.replace("/wallet");
			this.props.clearStorage(guestConstants.PAYMENT_SETTLEMENT_CLEAR);
		}

		if (this.props.getPaymentStatusData && this.props.getPaymentStatusCall == "success" && !this.state.paymentButtonEnable) {
			this.paymentEnable();
		}

		let intiatePaymentData = {};
		if (this.props.intiatePaymentData && this.props.intiatePaymentCall == "success") {
			this.props.updateOnRefresh(
				true,
				guestConstants.LOADER
			)
			intiatePaymentData = this.props.intiatePaymentData;
			if(intiatePaymentData?.name == "payu"){
				setTimeout(()=>{
					this.payumoney.current.submitForm();
				},2000)
			}else if (intiatePaymentData?.redirect.url) {
				window.location.href = intiatePaymentData.redirect.url
			}
		}

		let paymentConfig = "";
		if (hotel_params && hotel_params.payment_config) {
			paymentConfig = hotel_params.payment_config;
		}
		if (this.state.redirect) {
			this.props.history.replace(this.state.redirect);
		}

		if (this.props.getPaymentStatusCall == "success" && this.props.getPaymentStatusData && this.props.getPaymentStatusData.paid == true) {
			this.setState({
				redirect: "/wallet"
			})
		}
		return (
			<React.Fragment>
				{(this.props.loading ? <Loader /> : "")}

				<Layout location={this.props.location}>

					{Object.keys(this.props.hotelDetail).length > 0 ?
						<div className={`reservation-screen-wrapper ${(formData && (formData.formSteps == 1 || formData.formSteps == 4) && ((formData.noOfGuest == 2 && !this.state.forOnlyOnePerson) || formData.formSteps == 4)) ? 'multiple' : 'single'}`}>
							{/* <form onSubmit={this.saveStateData}> */}
							<StickyContainer className="reservation-detail">
								<MediaQuery minDeviceWidth={500}>
									<Header />
								</MediaQuery>
								{(formData && formData.formSteps == 4) ? <h2>{t('payment')}</h2>
									: formData && formData.formSteps == 3 ?
										<h2>{t('review')}</h2> :
										<h2>{
											(guestDetail?.guests_profile?.length>0 && guestDetail.guests_profile[formData.noOfGuest-1]?.last_name && anonymous_guest===false)?
												guestDetail.guests_profile[formData.noOfGuest-1].last_name:
												t('adult')+" "+(((formData?.noOfGuest)? formData.noOfGuest:""))
											}</h2>
								}
								<h4>
									<span>{t('booking_id')} </span>
									<span>{guestDetail.external_booking_id}</span>
								</h4>

								<div className="process-section">
									<MediaQuery minDeviceWidth={500}>
										<Sticky topOffset={0} relative={true}>
											{({ style, isSticky }) => (
												<div style={this.getStickyDesktopStyle(style)}
													className={`process-section-wrapper ${isSticky ? "sticky" : ""}`}>
													<div className="backdrop-shadow" />
													<div className={`sticky-process ${isSticky ? "sticky" : ""}`} />
													<div className="process-section-container">
														<div className="process-section-content">
															<span className={(formData && formData.formSteps == 1) ? "active" : formData && formData.formSteps > 1 ? "complete" : ""} onClick={(e) => (formData && formData.formSteps > 1 && formData.formSteps < 4 ? this.handlePreviousStep(e, 1) : "")}>{t('id')}<i className="arrow right"></i></span>
															<span className={(formData && formData.formSteps == 2) ? "active" : formData && formData.formSteps > 2 ? "complete" : ""} onClick={(e) => (formData && formData.formSteps > 1 && formData.formSteps < 4 ? this.handlePreviousStep(e, 1) : "")}>{t('profile')}<i className="arrow right"></i></span>
															<span className={(formData && formData.formSteps == 3) ? "active" : formData && formData.formSteps > 3 ? "complete" : ""}
															>{t('sign')}</span>														
															{(paymentConfig.name && reservationParams.deposit_amount > 0) ?
																<span className={(formData && formData.formSteps == 4) ? "active" : formData && formData.formSteps > 4 ? "complete" : ""}>
																	<i className="payment arrow right"></i>{t('pay')}</span>
																: ""}
														</div>
													</div>
												</div>
											)}
										</Sticky>
									</MediaQuery>
									<MediaQuery maxDeviceWidth={500}>
										<Sticky topOffset={70}>
											{({ style, isSticky }) => (
												<div style={this.getStickyStyle(style)}
													className={`process-section-wrapper ${isSticky ? "sticky" : ""}`}>
													<div className="backdrop-shadow" />
													<div className={`sticky-process ${isSticky ? "sticky" : ""}`} />
													<div className="process-section-container">
														<div className="process-section-content">
															<span className={(formData && formData.formSteps == 1) ? "active" : formData && formData.formSteps > 1 ? "complete" : ""} onClick={(e) => (formData && formData.formSteps > 1 && formData.formSteps < 4 ? this.handlePreviousStep(e, 1) : "")}>{t('id')} <i className="arrow right"></i></span>
															<span className={(formData && formData.formSteps == 2) ? "active" : formData && formData.formSteps > 2 ? "complete" : ""} onClick={(e) => (formData && formData.formSteps > 1 && formData.formSteps < 4 ? this.handlePreviousStep(e, 2) : "")}>{t('profile')} <i className="arrow right"></i></span>
															<span className={(formData && formData.formSteps == 3) ? "active" : formData && formData.formSteps > 3 ? "complete" : ""}>{t('sign')}</span>
															
															{(paymentConfig.name && reservationParams.deposit_amount > 0) ?
																<span className={(formData && formData.formSteps == 4) ? "active" : formData && formData.formSteps > 4 ? "complete" : ""}>
																	<i className="payment arrow right"></i>
																	{t('pay')}
																</span>
																: ""}
														</div>
													</div>
												</div>
											)}
										</Sticky>
									</MediaQuery>
								</div>

								{(formData && formData.formSteps == 1 && Object.keys(this.props.hotelDetail).length > 0) ?
									<IDScan
										editableStatus={this.state.editableStatus}
										editableState={this.state}
										countryList={countryList}
										hotelDetail={this.props.hotelDetail}
										scanDocument={this.scanDocument}
										ocrDataError={this.state.ocrDataError}
										currentScanDocument={this.state.currentScanDocument}
										clearOcrScanFailed={this.clearOcrScanFailed}
										documentChangeUpdate={this.documentChangeUpdate}
										selectGuestProfileDoc={this.selectGuestProfileDoc} /> : ''
								}

								{(formData && (formData.formSteps == 2) && Object.keys(this.props.hotelDetail).length > 0) ?
									<Profile
										selectCountryData={this.selectCountryData}
										selectCountryCode={this.selectCountryCode}
										profileData={this.state.guest_profile}
										travelData={this.state.travel_detail}
										profileCustomData={this.state.guest_custom_field}
										documentData={this.state.guest_document}
										hotelDetail={this.props.hotelDetail}
										updateProfle={this.guestProfileInput}
										updateTravel={this.guestTravelInput}
										updateProfleCustom={this.guestProfileCustomInput}
										updateIDDocument={this.guestIDDocumentInput}
										updateVisaDocument={this.guestVisaDocumentInput}
										internationalGuest={this.state.internationalGuest}
										countryList={countryList}
										validator={this.validator}
										primary={(formData && formData.noOfGuest > 1)
											? false : true} /> : ''
								}

								{(formData && formData.formSteps == 3) ?
									<Sign
										guestDetail={this.props}
										getSignatureImage={this.getSignatureImage}
										covidQuestionAns={this.state.covid_question_ans}
										covidAnswer={this.covidAnswer}
									/>
									: ''
								}

								{(formData && formData.formSteps == 4) ?
									<Pay
										guestDetail={this.props}
										// paymentEnable = {this.paymentEnable}
										paymentError={(this.props.paymentError) ?
											this.props.paymentError : ""}
									// paymentDetail={paymentDetail}
									/>
									: ''
								}
							</StickyContainer>
							{(formData && formData.formSteps == 4 && paymentConfig && paymentConfig.name.toLowerCase() == "payu") ?
								<PayUMoneyForm payuRequest={intiatePaymentData} ref={this.payumoney}/> :
								""}
							{(formData && (formData.formSteps == 1 || formData.formSteps == 4) && ((formData.noOfGuest == 2 && !this.state.forOnlyOnePerson) || formData.formSteps == 4) ?
								<MultipleBtn
									totalStep={(paymentConfig.name && guestDetail.deposit_amount > 0) ? "4" : "3"}
									currentStep={formData.formSteps}
									subStep={formData.noOfGuest}
									secondaryBtnText={(formData.formSteps == 4) ? t('settle_payment_at_the_reception') : t('i_am_staying_alone')}
									currentStep={formData.formSteps}
									btnTitle={this.state.btnTitle}
									secondaryBtn={(e) => this.secondaryBtn(formData.formSteps)}
									saveData={(e) => this.saveStateData(formData.formSteps)}
									disabled={(this.state.paymentButtonEnable && (formData && formData.formSteps == 4)) || ((formData && formData.formSteps > 1 && formData.formSteps < 4) || (formData && formData.formSteps == 1 && this.state.selectNationality && this.state.selectIdType && this.state.scanDocument)) ? false : true}
								/>
								:
								<SingleBtn
									signValidation={this.state.signValidation}
									covidValidation={!this.state.covidStatus}
									totalStep={(paymentConfig.name && guestDetail.deposit_amount > 0) ? "4" : "3"}
									currentStep={formData.formSteps}
									subStep={formData.noOfGuest}
									btnTitle={this.state.btnTitle}
									disabled={(formData && formData.formSteps > 1) ||
										(formData && formData.formSteps == 1 &&
											this.state.selectNationality &&
											this.state.selectIdType &&
											this.state.scanDocument) ? false : true}
									saveData={(e) => this.saveStateData(formData.formSteps)}
								/>)}

							{/* </form> */}
						</div>
						: ""}
				</Layout>
			</React.Fragment>
		);
	}
}

function mapState(state) {
	return {
		guestDetail: state.guest.guestDetail,
		hotelDetail: state.guest.hotelDetail,
		countryData: state.guest.countryData,
		checkindetail: state.guest.checkindetail,
		ocrDocument: state.guest.ocrDocument,
		ocrDocumentCall: state.guest.ocrDocumentCall,
		intiatePaymentData: state.guest.intiatePaymentData,
		intiatePaymentCall: state.guest.intiatePaymentCall,
		getPaymentStatusCall: state.guest.getPaymentStatusCall,
		getPaymentStatusData: state.guest.getPaymentStatusData,
		saveGuestImagesStatus: state.guest.saveGuestImagesStatus,
		loading: state.guest.loading,
		guestProfile: state.guest.guestProfile,
		paymentSettleCall: state.guest.paymentSettleCall,
		paymentError: state.guest.paymentError,
		saveGuestImagesStatusCall: state.guest.saveGuestImagesStatusCall
	};
}

const actionCreators = {
	updateFormData: guestActions.updateFormData,
	saveGuestProfiles: guestActions.saveGuestProfiles,
	saveGuestIDImages: guestActions.saveGuestIDImages,
	updateOnRefresh: guestActions.updateOnRefresh,
	clearSaveGuestDoc: guestActions.clearSaveGuestDoc,
	clearSaveGuestProfile: guestActions.clearSaveGuestProfile,
	scanDocument: guestActions.scanDocument,
	clearStorage: guestActions.clearStorage,
	paymentSettlement: guestActions.paymentSettlement,
	getPaymentDetail: guestActions.getPaymentDetail,
	intiatePayment: guestActions.intiatePayment,
	getPaymentStatus: guestActions.getPaymentStatus
};
export default connect(mapState, actionCreators)((checkinDetail));