import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/css/styles.scss';
//import '../assets/css/floating.scss';
import './assets/css/initial.scss';
import './assets/css/header.scss';
import './assets/css/animation.scss';

import { Provider } from 'react-redux'
import { store } from './_helpers';
// import {I18nextProvider} from 'react-i18next';
// import i18n from './lib/i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <I18nextProvider i18n={i18n}> */}
        <App />
      {/* </I18nextProvider> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
