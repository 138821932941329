import React from "react";
import GuestDetail from '../components/guestdetail';
import Signature from "./signature";
import { t,currentLanguage } from '../utils';
class Sign extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let guestDetail = {};
        // let {t} = this.props;
        let reservatonDetail = {};
        let terms_and_conditions = "";
        let hotelDetail = {};
        if(this.props.guestDetail){
            if(this.props.guestDetail.guestProfile && this.props.guestDetail.guestProfile.length>0)
                guestDetail = (this.props.guestDetail.guestProfile[0].guest_profile);
            
            reservatonDetail =  this.props.guestDetail.guestDetail;
        }
        
        let covid_question_ans = [];
        if(this.props.covidQuestionAns) {
            covid_question_ans = this.props.covidQuestionAns;
        }

        if (this.props.guestDetail) {
            hotelDetail = this.props.guestDetail.hotelDetail;            
            if(hotelDetail.hotel_locale){
                let language = currentLanguage();
                console.log(language,"language")
                let localeData = hotelDetail.hotel_locale[language];
                if(localeData){
                    terms_and_conditions = localeData.find(function(data){
                        return (data.tag == "terms_and_conditions");
                    })
                }
            }
        }        
        
        return(  
           <div>
               <div className="guest-info">
                   <GuestDetail id={t('guest')} value={guestDetail.first_name+" "+guestDetail.last_name} />
                   <div className="guest-2column">
                       <GuestDetail id={t('arrival')} value={(reservatonDetail.arrival_date)?reservatonDetail.arrival_date:'NA'}/>
                       <GuestDetail id={t('departure')}  value={(reservatonDetail.departure_date)?reservatonDetail.departure_date:'NA'} />
                   </div>
                   <GuestDetail id={t('room_type')} value={(reservatonDetail.room_type)?reservatonDetail.room_type:'NA'}  />
                   {/* <GuestDetail id={t('billing_instructions')} value="N/A" /> */}
                   <GuestDetail id={t('room_tariff')} value={(reservatonDetail.room_rate)?reservatonDetail.currency_symbol+reservatonDetail.room_rate:'NA'} />
                   {/* <GuestDetail id={t('meal_plan')} value="N/A" /> */}
                   <div className="guest-2column">
                       <GuestDetail id={t('adults')} value={(reservatonDetail.no_of_adult)?reservatonDetail.no_of_adult :0} />
                       <GuestDetail id={t('kids')} value={(reservatonDetail.no_of_child)?reservatonDetail.no_of_child:0} />
                   </div>
                   {(reservatonDetail.rate_code_description)? <div className="guest-info-detail">
                       <div className="guest-id">{t('rate_description')}</div>
                       <div className="guest-value">
                            <div>{(reservatonDetail.rate_code_description)?reservatonDetail.rate_code_description:'NA'}</div>
                           {/* <div>{t('available_rate')}</div>
                           <div>{t('including_breakfast')}</div> */}
                       </div>
                   </div>
                   :""}
                   {/* <div className="rules-regulations"  dangerouslySetInnerHTML={{ __html: terms_and_conditions.text}}>
                
                   </div> */}
                   <div className="rules-regulations">
                        {(terms_and_conditions && terms_and_conditions.text)?<h2>{t("terms_and_conditions_label")}</h2>:""}
                        
                        {(terms_and_conditions && terms_and_conditions.text)?
                        <div className="" dangerouslySetInnerHTML={{ __html: terms_and_conditions.text}}></div>:""}
                       
                   </div>
                   {((covid_question_ans)&&covid_question_ans.length>0)?
                   <div className="rules-regulations health_declaration">
                       <h2>{t("health_declarations")}</h2>
                       {/* <div className="declaration_options"><span>Yes</span><span>No</span></div> */}
                       {covid_question_ans.map((data,index) => (
                           <div className="health_question_wrapper">
                               <div className="radio_label">{data.question}</div>
                               <div className="radio_container">
                                 <label className="container_radio">
                                        {t("yes")}
                                        <input type="radio" name={"declaration_options_"+index} value={true} checked={(covid_question_ans[index].answer==="")?"":(covid_question_ans[index].answer)?true:false} onClick={()=>this.props.covidAnswer(index, true)}/>
                                        
                                            <span className="checkmark_check"></span>
                                        </label>
                                        <label className="container_radio">
                                        {t("no")}
                                        <input type="radio" name={"declaration_options_"+index}  value={false} checked={(covid_question_ans[index].answer==="")?"":(!covid_question_ans[index].answer)?true:false} onClick={()=>this.props.covidAnswer(index, false)}/>
                                            <span className="checkmark_check"></span>
                                        </label>
                                {/* <span className="radio_image"><img src={(data.answer)?Check:UnCheck} /></span>
                                <span className="radio_image"><img src={(data.answer)?UnCheck:Check} /></span> */}
                                
                            </div>
                           </div>
                       ))}
                   </div>
                   :""}
                   <div className="payment-wrapper-option">
                                      
                                    </div>
                   <span className="separator_sign"></span>
                    <Signature 
                    SignatureData={this.props.getSignatureImage}/>
               </div>
           </div>
        );
    }
}
export default Sign;