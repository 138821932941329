import React from "react";
import $ from 'jquery';
import { t_with_params } from "../../utils";

class SingleBtn extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("Sign Component====",nextProps)
        if(!this.props.disabled) {
            if($(".container").hasClass("keyboard_up")) {
                $(".container").removeClass("keyboard_up");
            }
        }
		return nextProps != this.props;

    }
    componentWillReceiveProps
    render() {
        let current = this.props.currentStep+""+(this.props.currentStep<3&&(this.props.subStep>1)?"."+(this.props.subStep-1):"");
        let total = this.props.totalStep;
        return(
            <div className="footer-btn">
                {/*Remove in case of specific theme */}
                <div className="footer-backdrop-default" />
                {/*Remove in case of specific theme*/}
                
                <button type="button" className= {this.props.disabled || (this.props.currentStep==3 && this.props.signValidation) || (this.props.currentStep==3 && this.props.covidValidation) ? 'primary-button option-display disabled': 'primary-button option-display'}
                 disabled={this.props.disabled || (this.props.currentStep==3 && this.props.signValidation) || (this.props.currentStep==3 && this.props.covidValidation)  ?"disabled":""}  onClick={this.props.saveData}>
                     {/* Step {this.props.currentStep}{this.props.currentStep<3&&(this.props.subStep>1)?"."+(this.props.subStep-1):""} of {this.props.totalStep} */}
                    <span>{t_with_params("step_current_of_total",{"{current}":current,"{total}":total})}</span>
                    {/* <span></span>  */}
                    <span>{this.props.btnTitle}<i className="arrow right"></i></span>
                </button>
            </div>
        );
    }
}

export default SingleBtn;