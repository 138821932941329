import React from "react";

class GuestDetail extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="guest-info-detail">
                <div className="guest-id">{this.props.id}</div>
                <div className="guest-value">{this.props.value}</div>
            </div>
        );
    }
}

export default GuestDetail;