import React from "react";
import Layout from "../components/layout";
// import { withTranslation } from '../components/lang';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import queryString from 'query-string';
import { t } from '../utils';


class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {redirect:""};
    }

    componentDidMount(){ 
      setTimeout(() => {
        let params = queryString.parse(this.props.location.search);	
        let dataArray = {};
        let hotelDetail = (localStorage.getItem('hotelData'))?
                        JSON.parse(localStorage.getItem('hotelData'))
                        :"";
        /**
         * Payment Gateway Conditions
         */		
        console.log(params,"fdsfdfdsfdfdfs",hotelDetail)
        if(hotelDetail.payment_config){
          let paymentConfig = hotelDetail.payment_config;
          if((paymentConfig.name) && paymentConfig.name == "Ingenico") {
            dataArray.transaction_id = params.transactionId;
            dataArray.invoice_id = params.orderNumber;
            dataArray.payment_status = (params.statusCode=="APPROVED" || params.statusCode=="CAPTURED")?true:false;
            dataArray.transaction_data = JSON.stringify(params);
          } else if((paymentConfig.name) && paymentConfig.name == "2c2p") {
            console.log(params,"::PARAMS","paymentConfig::",paymentConfig.name);
            dataArray.transaction_id = "";
            dataArray.invoice_id = params.invoiceNo;
            dataArray.payment_status = (params.respCode==2000)?true:false;
            dataArray.transaction_data = JSON.stringify(params);
          } else if((paymentConfig.name) && paymentConfig.name.toLowerCase() == "payu") {
            dataArray.transaction_id = params.mihpayid;
            dataArray.invoice_id = params.txnid;
            dataArray.payment_status = (params.status=="success")?true:false;
            dataArray.transaction_data = JSON.stringify(params);
          }
        }else{
          // this.setState({
          //   redirect:"/booking"
          // })
        }
        
        if(Object.keys(dataArray).length>0){
          if(dataArray.payment_status){
            this.props.updatePaymentStatus(dataArray);            
          }else {
            this.props.updatePaymentStatus(dataArray);  
            let guestData = this.props;
            guestData.guest.checkindetail.formSteps = 4;
            guestData.guest.paymentError = true;
            this.props.updateFormData(guestData);
            this.setState({
              redirect:"/checkindetail"
            })
          }
        }          
      })
    }
    
    render() {
      // let {t} = this.props;
      if((this.props.guest.updatePaymentStatus) && this.props.guest.updatePaymentStatusCall=="success") {
          this.props.history.push("/wallet");
      }

      if((this.props.guest.updatePaymentStatus) && this.props.guest.updatePaymentStatusCall=="failed") {
          
          this.props.history.push("/wallet");
      }

      if (this.state.redirect) {
        this.props.history.push(this.state.redirect);
      }

      return (
        // <Layout is_outer>
        <div className="main">
            <div className="background-desktop">
              {/* <img src={(hotelBackground) ? hotelBackground.file_path : Background} alt="background" /> */}
            </div>
            <div className="main-thru-container">
              <div className="error-component-wrapper">
                <div className="error-component-modal">
                    <div className="error-heading">
                        {t("payment_processing")}
                    </div>
                    <div className="error-description">
                        {t("please_wait")}
                    </div>
                </div>
              </div>
            </div>
        </div>
        // </Layout>
      );
    }
}
function mapState(state) {
  return state;
}
const actionCreators = {
  updatePaymentStatus: guestActions.updatePaymentStatus,
  updateFormData: guestActions.updateFormData,
  clearStorage: guestActions.clearStorage
};
export default connect(mapState,  actionCreators)((Payment));