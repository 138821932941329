import { guestConstants } from '../_constants';

let initialState = {
  checkindetail: {
    noOfGuest: 1,
    formSteps: 1,
  },
  guestProfile: [],
  countryData: [],
  hotelDetail:{},
  hotelDetailCall:"",
  guestDetail:{},
  reservationParams:{},
  guestDetailCall:"",
  loading:true
}

export function guest(state = initialState, action) {
  switch (action.type) {

    case guestConstants.GET_COUNTRY_DATA_REQUEST:
      return {
        ...state,
        countryData: action.data,
        loading: true
      };

    case guestConstants.GET_COUNTRY_DATA_SUCCESS:
      return {
        ...state,
        countryData: action.data,
        loading: false
      };

    case guestConstants.GET_RESERVATION_DATA_SUCCESS:
      return {
        ...state,
        reservationParams: action.data,
        loading: false
      };  
    case guestConstants.GET_COUNTRY_DATA_FAILED:
      return {
        ...state,
        countryData: action.data,
        loading: false
      };

    case guestConstants.GET_HOTEL_DETAIL_REQUEST:
      return {
        ...state,
        hotelDetail: action.data,
        hotelDetailCall:"request",
        loading: true
      };
    case guestConstants.GET_HOTEL_DETAIL_SUCCESS:
      return {
        ...state,
        hotelDetail: action.data,
        hotelDetailCall:"success",
        loading: false
      };

    case guestConstants.GET_HOTEL_DETAIL_FAILED:
      return {
        ...state, hotelDetailCall:"failed",loading: false
      };

    case guestConstants.SAVE_GUEST_ID_IMAGES_REQUEST:
      return {
        ...state,
        saveGuestImagesStatusCall:"request",
        saveGuestImagesStatus: action.data,
        loading: true
      };

    case guestConstants.SAVE_GUEST_ID_IMAGES_SUCCESS:
      return {
        ...state,
        saveGuestImagesStatusCall:"success",
        saveGuestImagesStatus: action.data,
        loading: true
      };

    case guestConstants.SAVE_GUEST_ID_IMAGES_FAILED:
      return {
        ...state,
        saveGuestImagesStatusCall:"failed",
        loading: false
      };

    case guestConstants.SAVE_GUEST_ID_IMAGES_CLEAR:
      return {
        ...state,
        saveGuestImagesStatus: {}
      };

    case guestConstants.SAVE_GUEST_PROFILE_CLEAR:
      return {
        ...state,
        saveGuestProfile: action.data
      };  

    case guestConstants.UPDATE_FORMDATA:
      console.log(action.formData,"action.formData.checkindetail")
      const checkinDetail = Object.assign({}, action.formData.checkindetail);
      return {
        ...state,
        // checkindetail: checkinDetail,
        ...action.formData
        // loading: false
      };

    case guestConstants.GET_GUEST_DETAIL_REQUEST:
      return {
        ...state,
        guestDetail: action.data,
        guestDetailCall:"request",
        loading: true
      };

    case guestConstants.GET_GUEST_DETAIL_SUCCESS:
      return {
        ...state,
        guestDetail: action.data,
        guestDetailCall:"success",
        loading: false
      };

    case guestConstants.GET_GUEST_DETAIL_FAILED:
      return {
        ...state,
        guestDetailCall:"failed",
        loading: false
      };  

      case guestConstants.LOADER:
        return {
          ...state,
          loading: action.data
        };

      case guestConstants.GET_OCR_DOCUMENT_REQUEST:
        return {
          ...state,
          ocrDocument: action.data,
          ocrDocumentCall:"request",
          loading: true
        };

      case guestConstants.GET_OCR_DOCUMENT_SUCCESS:
        return {
          ...state,
          ocrDocument: action.data,
          ocrDocumentCall:"success",
          loading: false
        };

      case guestConstants.GET_OCR_DOCUMENT_FAILED:
        return {
          ...state,
          ocrDocumentCall:"failed",
          loading: false
        };
      case guestConstants.GET_OCR_DOCUMENT_CLEAR:
        return {
          ...state,
          ocrDocument: {},
          ocrDocumentCall:"",
          loading: false
        };
        
    case guestConstants.PAYMENT_SETTLEMENT_REQUEST:
      return {
        ...state,
        paymentSettleData: action.data,
        paymentSettleCall:"request",
        loading: true
      };

    case guestConstants.PAYMENT_SETTLEMENT_SUCCESS:
      return {
        ...state,
        paymentSettleData: action.data,
        paymentSettleCall:"success",
        loading: false
      };

    case guestConstants.PAYMENT_SETTLEMENT_FAILED:
      return {
        ...state,
        paymentSettleCall:"failed",
        loading: false
      };
    case guestConstants.PAYMENT_SETTLEMENT_CLEAR:
      return {
        ...state,
        paymentSettleData: {},
        paymentSettleCall:"",
        loading: false
      };
    case guestConstants.PAYMENT_DETAIL_REQUEST:
      return {
        ...state,
        paymentDetail: action.data,
        paymentDetailCall:"request",
        loading: true
      };

    case guestConstants.PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        paymentDetail: action.data,
        paymentDetailCall:"success",
        loading: false
      };

    case guestConstants.PAYMENT_DETAIL_FAILED:
      return {
        ...state,
        paymentDetailCall:"failed",
        loading: false
      };
    case guestConstants.PAYMENT_DETAIL_CLEAR:
      return {
        ...state,
        intiatePaymentData: {},
        intiatePaymentCall:"",
        loading: false
      };
    case guestConstants.INTIATE_PAYMENT_REQUEST:
      return {
        ...state,
        intiatePaymentData: action.data,
        intiatePaymentCall:"request",
        loading: true
      };

    case guestConstants.INTIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        intiatePaymentData: action.data,
        intiatePaymentCall:"success",
        loading: false
      };

    case guestConstants.INTIATE_PAYMENT_FAILED:
      return {
        ...state,
        intiatePaymentCall:"failed",
        loading: false
      };
    case guestConstants.INTIATE_PAYMENT_CLEAR:
      return {
        ...state,
        intiatePaymentData: {},
        intiatePaymentCall:"",
        loading: false
      };
    case guestConstants.UPDATE_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        updatePaymentStatus: action.data,
        updatePaymentStatusCall:"request",
        loading: true
      };

    case guestConstants.UPDATE_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        updatePaymentStatus: action.data,
        updatePaymentStatusCall:"success",
        loading: false
      };

    case guestConstants.UPDATE_PAYMENT_STATUS_FAILED:
      return {
        ...state,
        updatePaymentStatusCall:"failed",
        loading: false
      };
    case guestConstants.UPDATE_PAYMENT_STATUS_CLEAR:
      return {
        ...state,
        updatePaymentStatus: {},
        updatePaymentStatusCall:"",
        loading: false
      };
    case guestConstants.SEND_EMAIL_STATUS_REQUEST:
      return {
        ...state,
        sendEmailStatus: action.data,
        sendEmailStatusCall:"request",
        loading: true
      };

    case guestConstants.SEND_EMAIL_STATUS_SUCCESS:
      return {
        ...state,
        sendEmailStatus: action.data,
        sendEmailStatusCall:"success",
        loading: false
      };

    case guestConstants.SEND_EMAIL_STATUS_FAILED:
      return {
        ...state,
        sendEmailStatusCall:"failed",
        loading: false
      };
    case guestConstants.SEND_EMAIL_STATUS_CLEAR:
      return {
        ...state,
        sendEmailStatus: {},
        sendEmailStatusCall:"",
        loading: false
      };
    case guestConstants.GET_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        getPaymentStatusData: action.data,
        getPaymentStatusCall:"request",
        loading: true
      };

    case guestConstants.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        getPaymentStatusData: action.data,
        getPaymentStatusCall:"success",
        loading: false
      };

    case guestConstants.GET_PAYMENT_STATUS_FAILED:
      return {
        ...state,
        getPaymentStatusCall:"failed",
        loading: false
      };
    case guestConstants.GET_PAYMENT_STATUS_CLEAR:
      return {
        ...state,
        getPaymentStatusData: {},
        getPaymentStatusCall:"",
        loading: false
      };
    case guestConstants.GET_LEGAL_DOCUMENT_REQUEST:
      return {
        ...state,
        legalDocument: action.data,
        legalDocumentCall:"request",
        loading: true
      };

    case guestConstants.GET_LEGAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        legalDocument: action.data,
        legalDocumentCall:"success",
        loading: false
      };

    case guestConstants.GET_LEGAL_DOCUMENT_FAILED:
      return {
        ...state,
        legalDocumentCall:"failed",
        loading: false
      };
    case guestConstants.GET_LEGAL_DOCUMENT_CLEAR:
      return {
        ...state,
        legalDocument: {},
        legalDocumentCall:"",
        loading: false
      };
    default:
      return state
  }
}