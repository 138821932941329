import React from "react";
import Select from "./select";
import Scan from '../assets/images/scan1.svg';
import ScanDisable from '../assets/images/scan2.svg';
import Webcam from "react-webcam";
// import { withTranslation } from './lang';
import { t } from '../utils';
import $ from "jquery";

class IDScan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraFunction: false,
            currentScan: "",
            idProofImageFront: "",
            idProofImageBack: "",
            internationalGuest: false,
            visaImage: "",
            idTypeData: [],
            nationalityId: "",
            idDocumentScan: false,
            documentData: { scan_side: "" },
            selectDocType: ""
        }
        this.selectNationalityChild = this.selectNationalityChild.bind(this);
        this.selectIdTypeChild = this.selectIdTypeChild.bind(this);
        this.getIdtypeDataArray = this.getIdtypeDataArray.bind(this);
        this.ocrScanFailed = this.ocrScanFailed.bind(this);
        this.cameraOn = this.cameraOn.bind(this);
    }

    removeKeyboard() {
        if ($(".container").hasClass("keyboard_up")) {
            $(".container").removeClass("keyboard_up");
        }
    }

    getIdtypeDataArray(guestProfileData) {
        if (guestProfileData) {
            let initalIdTypeData = (this.props.hotelDetail.identity_document) ? this.props.hotelDetail.identity_document : [];
            let hotelData = this.props.hotelDetail;
            if (initalIdTypeData) {
                const getIDtypeData = initalIdTypeData.filter(function (country) {
                    country['text'] = country.document_type;
                    if (guestProfileData.id_detail.country_id != hotelData.country_id) {
                        console.log(country, "country")
                        return (country.country_id == guestProfileData.id_detail.country_id || country.country_id == null);
                    } else {
                        return (country.country_id == guestProfileData.id_detail.country_id);
                    }
                });
                let idData = getIDtypeData.find(function (data) {

                    console.log(data)
                    return guestProfileData.id_detail.docid_type == data.document_type;
                });
                console.log(idData, "idData", guestProfileData.id_detail.docid_type)
                idData = (idData == undefined) ? {} : idData;

                this.setState({
                    documentData: idData
                })
                return getIDtypeData || []
            }
        }

    }

    componentDidMount() {
        setTimeout(() => {
            let guestProfileData = this.props.editableState.guest_document;
            console.log(guestProfileData, "guestProfileData")
            this.setState({
                idProofImageFront: (guestProfileData?.id_detail?.doc_image_front ? guestProfileData.id_detail.doc_image_front : ""),

                idProofImageBack: (guestProfileData?.id_detail?.doc_image_back ? guestProfileData.id_detail.doc_image_back : ""),

                visaImage: (guestProfileData?.visa_detail?.visa_image ?
                    guestProfileData.visa_detail.visa_image : ""),

                internationalGuest: (guestProfileData?.visa_detail?.visa_image ? true : false),

                nationalityId: (guestProfileData?.id_detail?.country_id ?
                    guestProfileData.id_detail.country_id : ""),

                selectDocType: (guestProfileData?.id_detail?.docid_type ?
                    guestProfileData.id_detail.docid_type : ""),
                idTypeData: (guestProfileData != undefined) ? this.getIdtypeDataArray(guestProfileData) : ''
                // documentData:idData
            })
        }, 200)
    }


    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = (e) => {
        if(this.webcam.getScreenshot()==null){
            return true;
        }
        
        let idDocObject = {
            cameraFunction: false
        }
        
        this.removeKeyboard();

        let hotelDetail = this.props.hotelDetail;
        if (this.state.currentScan == "backImage") {
            idDocObject.idProofImageBack = this.webcam.getScreenshot()
        }

        if (this.state.currentScan == "frontImage") {
            idDocObject.idProofImageFront = this.webcam.getScreenshot()
        }

        if (this.state.currentScan == "visaImage") {
            idDocObject.visaImage = this.webcam.getScreenshot()
        }

        this.setState(idDocObject, () => {

            if (this.state.currentScan == "backImage" || this.state.currentScan == "frontImage") {
                if ((this.state.documentData.scan_side == "Both"
                    && (this.state.idProofImageBack && this.state.idProofImageFront))) {
                    let scan_document = (this.state.visaImage === "" && ((this.state.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true))) ? false : true;
                    this.props.selectGuestProfileDoc({
                        idProofImageFront: this.state.idProofImageFront,
                        idProofImageBack: this.state.idProofImageBack,
                        selectDocType: "id_detail"
                    }, "scan_document", scan_document)
                } else if ((this.state.documentData.scan_side == "Front"
                    && (this.state.idProofImageFront))) {
                    console.log({
                        idProofImageFront: this.state.idProofImageFront,
                        idProofImageBack: this.state.idProofImageBack,
                        selectDocType: "id_detail"
                    }, "capture front")
                    let scan_document = (this.state.visaImage === "" && ((this.state.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true))) ? false : true;
                    this.props.selectGuestProfileDoc({
                        idProofImageFront: this.state.idProofImageFront,
                        idProofImageBack: this.state.idProofImageBack,
                        selectDocType: "id_detail"
                    }, "scan_document", scan_document)
                }
            }
            if (((this.state.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true)) && this.state.currentScan == "visaImage") {

                this.props.selectGuestProfileDoc({
                    visaImage: this.state.visaImage,
                    selectDocType: "visa_detail"
                }, "scan_document", true)
            }
        });
    };

    cameraOn = (type) => {
        // document.getElementsByTagName("body")[0].classList.add("android_device");
        this.setState({
            cameraFunction: true,
            currentScan: type
        })
    }

    reScanDoc = (Type) => {
        if (Type == "Front") {
            this.setState({
                cameraFunction: false,
                idProofImageFront: ""
            })
        } else if ("Back") {
            this.setState({
                cameraFunction: false,
                idProofImageBack: ""
            })
        }
        this.props.selectGuestProfileDoc({}, "scan_document", false)
    }

    reScanVisaDoc = () => {
        this.setState({
            cameraFunction: false,
            visaImage: ""
        })
        this.props.selectGuestProfileDoc({}, "scan_document", false)
    }

    selectNationalityChild(event, selectedObj) {
        this.props.documentChangeUpdate(()=>{
            if (selectedObj != "" &&
            selectedObj != null &&
            selectedObj != undefined) {
            let hotelData = this.props.hotelDetail;

            this.removeKeyboard();

            if (hotelData.hasOwnProperty('country_id')) {
                let setStatus = ""
                if (selectedObj.id != hotelData.country_id) {
                    selectedObj.internationalGuest = true;
                    this.setState({
                        internationalGuest: true
                    })
                } else {
                    selectedObj.internationalGuest = false;
                    this.setState({
                        internationalGuest: false
                    })
                }
            }

            if (hotelData.hasOwnProperty('identity_document')) {
                let initialIdTyepData = hotelData.identity_document;
                let getIDtypeData = initialIdTyepData.filter(function (country) {
                    country['text'] = country.document_type;
                    if (selectedObj.id != hotelData.country_id) {
                        console.log(country, "country")
                        return (country.country_id == selectedObj.id || country.country_id == null);
                    } else {
                        return (country.country_id == selectedObj.id);
                    }
                });
                if (getIDtypeData.length == 0) {
                    this.props.selectGuestProfileDoc({}, "sacn_id", false)
                }

                this.setState({
                    idTypeData: getIDtypeData,
                    nationalityId: selectedObj.id,
                    currentScan: "",
                    idProofImageFront: "",
                    idProofImageBack: "",
                    selectDocType: "",
                    visaImage: ""
                }, () => {
                    this.props.selectGuestProfileDoc(selectedObj, "nationality", true)
                })
                this.props.selectGuestProfileDoc({}, "scan_document", false);
            }
        }
        });
        
    }

    selectIdTypeChild(event, selectedObj) {
        if (selectedObj != "" &&
            selectedObj != null &&
            selectedObj != undefined) {

            this.setState({
                documentData: selectedObj,
                selectDocType: selectedObj.document_type,
                currentScan: "",
                idProofImageFront: "",
                idProofImageBack: "",
                visaImage: "",
            })
            selectedObj.internationalGuest = this.state.internationalGuest;
            console.log(selectedObj, "selectedObj")
            this.props.selectGuestProfileDoc(selectedObj, "scan_document", false)
            this.props.selectGuestProfileDoc(selectedObj, "sacn_id", true);            
            this.removeKeyboard();
        }

    }

    ocrScanFailed() {
        console.log(this.props.currentScanDocument, "this.props.currentScanDocument", this.props.ocrDataError)
        if (this.props.currentScanDocument == "id_detail") {
            this.setState({
                cameraFunction: false,
                idProofImageBack: "",
                idProofImageFront: ""
            })
        } else if (this.props.currentScanDocument == "visa_detail") {
            this.setState({
                cameraFunction: false,
                visaImage: ""
            });
        }
        this.props.selectGuestProfileDoc({}, "scan_document", false)
        this.props.clearOcrScanFailed();
    }

    render() {
        console.log("id scan issue");
        // let { t } = this.props;
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "environment"
        };
        if (this.props.ocrDataError) {
            this.ocrScanFailed();
        }
        let hotelDetail = (this.props.hotelDetail) ? this.props.hotelDetail : {}
        // const countryProps = {
        //     options: this.props.countryList,
        //     getOptionLabel: (option) => option.text,
        // };
        // const idProps = {
        //     options: this.state.idTypeData,
        //     getOptionLabel: (option) => option.text,
        // };

        return (
            <div className="process-section-details">
                <div className="process-section-detail-header">
                    <h3>{t('scan_id_title')}</h3>
                </div>
                <div className="guest-2column">
                    <Select
                        id={t('nationality')}
                        onChangeHandle={this.selectNationalityChild}
                        dataList={this.props.countryList}
                        name="nationality"
                        labelId="demo-simple-select-label"
                        error={false}
                        desabled={false}
                        clear={false}
                        defaultValue={this.state.nationalityId}
                    />

                    <Select
                        desabled={this.state.nationalityId != "" ? false : true}
                        id={t('id_type')}
                        name="idType"
                        clear={true}
                        labelId="demo-simple-select-label"
                        onChangeHandle={this.selectIdTypeChild}
                        dataList={this.state.idTypeData}
                        error={false}
                        defaultValue={this.state.selectDocType}
                    />

                </div>
                {this.state.nationalityId && this.state.selectDocType ?
                    <div className="help_text_id">
                        <b>{t('please_note')}</b> {t('help_text_id')}
                    </div>
                    : ""}
                {(this.state.cameraFunction ?
                    <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>

                        <div className="scan-id">
                            <div className="scan-id-content">
                                <div className="web-cam-wrapper">
                                    <Webcam
                                        audio={false}
                                        height={350}
                                        ref={this.setRef}
                                        screenshotFormat="image/jpeg"
                                        width={350}
                                        videoConstraints={videoConstraints}
                                    />
                                    <div className="outer-circle" onClick={this.capture}><span className="inner-circle"><span className="inner"></span></span></div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <React.Fragment>
                        {(this.state.selectDocType && ((this.state.documentData) && (this.state.documentData.scan_side) && this.state.documentData.scan_side == "Both" || this.state.documentData.scan_side == "Front")) ? (this.state.idProofImageFront ? <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                            <div className="scan-id">
                                <div className="scan-id-content">
                                    <button className="scan-button" onClick={(type) => this.reScanDoc("Front")}>{t("rescan")}</button>
                                    <img src={this.state.idProofImageFront} alt="id prrof iamge" />
                                </div>
                            </div>
                        </div> : <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                                <div className="scan-id">
                                    {/* <div className="error_container">Invalid ID Card Scan</div> */}
                                    <div className="scan-id-content" onClick={() => this.cameraOn('frontImage')}>
                                        <img src={this.state.nationalityId && this.state.selectDocType ? Scan : ScanDisable} alt="scan" width="100" />
                                        {t("tap_to_scan_front")}
                                    </div>
                                </div>
                            </div>) : ""}
                        {((this.state.documentData) && (this.state.documentData.scan_side) && this.state.documentData.scan_side == "Both") ? (this.state.idProofImageBack ?
                            <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                                <div className="scan-id">
                                    <div className="scan-id-content">
                                        <button className="scan-button" onClick={(type) => this.reScanDoc("Back")}>{t("rescan")}</button>
                                        <img src={this.state.idProofImageBack} alt="id prrof iamge" />
                                    </div>
                                </div>
                            </div> :
                            <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                                <div className="scan-id">
                                    <div className="scan-id-content" onClick={() => this.cameraOn('backImage')}>
                                        <img src={this.state.nationalityId && this.state.selectDocType ? Scan : ScanDisable} alt="scan" width="100" />
                                        {t("tap_to_scan_back")}
                                    </div>
                                </div>
                            </div>
                        ) : ""}

                        {(this.state.selectDocType && ((this.state.internationalGuest === true && hotelDetail.show_visa_detail.intl === true) || (hotelDetail.show_visa_detail.local === true)) ?
                            <React.Fragment>
                                {(this.state.visaImage ?
                                    <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                                        <div className="scan-id">
                                            <div className="scan-id-content">
                                                <button className="scan-button" onClick={this.reScanVisaDoc}>{t("rescan")}</button>
                                                <img src={this.state.visaImage} alt="Visa prrof iamge" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={`scan-id-container ${this.state.nationalityId && this.state.selectDocType ? 'active' : ''}`}>
                                        <div className="scan-id">
                                            <div className="scan-id-content" onClick={() => this.cameraOn('visaImage')}>
                                                <img src={this.state.nationalityId && this.state.selectDocType ? Scan : ScanDisable} alt="scan" width="100" />
                                                {t("tap_to_scan_visa")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                            : "")

                        }

                    </React.Fragment>
                )}
            </div>
        );
    }
}
export default IDScan;