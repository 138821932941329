import React, { Component, Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import Background from '../assets/images/background.jpg';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import { guestConstants } from '../_constants';
import { Redirect, withRouter } from "react-router-dom";
import { t,currentLanguage } from '../utils';


class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = { redirect: "" };
	}

	componentDidMount() {
		setTimeout(() => {

			let localStoregeHotelCode = localStorage.getItem('hotel_code');
			let localStorageReservationCode = localStorage.getItem('reservation_code');
			let localStorageCountryData = localStorage.getItem('countryData');
			let localStorageResParams = localStorage.getItem('reservationParams');
			let localStorageReservationData = localStorage.getItem("reservationData");

			if (localStoregeHotelCode != undefined &&
				localStoregeHotelCode != "undefined" &&
				localStoregeHotelCode != "") {
				this.props.dispatch(
					guestActions.updateOnRefresh(
						JSON.parse(localStorage.getItem("hotelData")),
						guestConstants.GET_HOTEL_DETAIL_SUCCESS
					)
				)
			}

			if (localStorageReservationData != undefined &&
				localStorageReservationData != "undefined" &&
				localStorageReservationData != "") {
				this.props.dispatch(
					guestActions.updateOnRefresh(
						JSON.parse(localStorage.getItem("reservationData")),
						guestConstants.GET_GUEST_DETAIL_SUCCESS
					)
				)
			}

			if (localStorageCountryData != undefined &&
				localStorageCountryData != "undefined" &&
				localStorageCountryData != "") {
				this.props.dispatch(
					guestActions.updateOnRefresh(
						JSON.parse(localStorage.getItem("countryData")),
						guestConstants.GET_COUNTRY_DATA_SUCCESS
					)
				)
			}

			if (localStorageResParams != undefined &&
				localStorageResParams != "undefined" &&
				localStorageResParams != "") {
				this.props.dispatch(
					guestActions.updateOnRefresh(
						JSON.parse(localStorage.getItem("reservationParams")),
						guestConstants.GET_RESERVATION_DATA_SUCCESS
					)
				)
			}

			if ((!this.props.guestDetail || Object.keys(this.props.guestDetail) == 0) && !(localStorageResParams)) {
				console.log(localStorageResParams,"localStorageReservationData")
				if ((this.props.location.pathname != "/wallet" && this.props.location.pathname != "/payment") && this.props.location.pathname != "/booking") {
					localStorage.removeItem('hotel_code');
					localStorage.removeItem("hotelData");
					localStorage.removeItem('reservation_code');
					localStorage.removeItem("reservationData");
					localStorage.removeItem("reservationParams");
					this.setState({
						redirect: "/booking"
					})
				}
			}
		});
	}

	componentDidUpdate() {
	}

	render() {
		let props = this.props;
		let hotelDetail = {};
		let hotelDarkLogo = "";
		let hotelBackground = "";
		if (this.props.guest.hotelDetail) {
			hotelDetail = this.props.guest.hotelDetail;
			if (hotelDetail.hotel_assets) {

				hotelDarkLogo = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_logo_dark")
				});

				hotelBackground = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_background_image")
				});
			}
		}
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />
		}
		return (
			<div className={`main ${currentLanguage()}`}>
				{(!props.booking_page) ?
					<div className="background-desktop">
						<img src={(hotelBackground) ? hotelBackground.file_path : Background} alt="background" onError={(e) => { e.target.src = Background }} />
					</div>
					:
					""
				}
				<div className={`main-thru-container ${(props.is_outer && !props.booking_page) ? '' : 'outer'}`}>
					<div className="header-container-outer">
						<Header is_outer={props.is_outer} hotelDarkLogo={hotelDarkLogo} hotelDetail={this.props.guest.hotelDetail} />
					</div>
					<div className={` ${props.is_outer ? 'initial-screen-wrapper' : 'container'} ${props.booking_page ? 'booking_page' : ''}`}>
						{(props.is_outer && !props.booking_page) ?
							<div>
								<div className="initial-screen-content-background">
									<img src={(hotelBackground) ? hotelBackground.file_path : Background} alt="background" onError={(e) => { e.target.src = Background }} />
								</div>
								<div className="image-overlay" />
							</div> : ''}
						{props.children}
					</div>
					<Footer />
				</div>
				<div className="landscape-orientation">
					<div className="error-component-wrapper">
						<div className="error-component-modal">
							<div className="error-heading">
								{t("please_rotate")}
              </div>
							<div className="error-description">
								{t("please_rotate_your_device")}
              </div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapState(state) {
	return {
		guest: {
			hotelDetail: state.guest.hotelDetail,
		},
		guestDetail: state.guest.guestDetail
	};
}
export default connect(mapState)(Layout);