import React from "react";
import Logo from '../assets/images/logo.png';
import LogoHotel from '../assets/images/logo.png';
import { connect } from 'react-redux';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hotelDetail = {};
		let hotelDarkLogo = "";
		if (this.props.hotelDetail) {
			hotelDetail = this.props.hotelDetail;
			if (hotelDetail.hotel_assets) {

				hotelDarkLogo = hotelDetail.hotel_assets.find(function (assets) {
					return (assets.asset_type == "hotel_logo_dark")
				});
			}
		}
         return(
            <div className="">
                {/* <Head> */}
                    {/* <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
                    <meta name="theme-color" content="#fff"/>
                    <meta name="description" content="Thru App by digiValet"/>
                    <title>Thru</title>
                    <link rel="icon" href="../public/favico.png"/> */}
                {/* </Head> */}
                {(!this.props.is_outer)?
                    <div className="header-logo-container">
                        <div className="hotel-logo-inner">
                            <img src={(hotelDarkLogo)?hotelDarkLogo.file_path:LogoHotel} alt="hotel-logo" onError={(e) => {e.target.src = Logo}}/>
                        </div>
                        <div className="thru-logo">
                            <img src={Logo} alt="thru logo" />
                        </div>
                    </div>
                :''}
            </div>
        );
    }
}

function mapState(state) {
	return {
		hotelDetail: state.guest.hotelDetail
	};
}
export default (connect(mapState)(Header));