import React from "react";
import { connect } from 'react-redux';
// import { withTranslation } from './lang';
import GuestDetail from "./guestdetail";
import check from '../assets/images/Checkbox.svg';
import { t, currentLanguage } from '../utils';
class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {payment_mode:""}
    }

    // selectPaymentMode = (paymentMode) => {
    //     console.log(paymentMode,"paymentMode")
    //     this.setState({
    //         payment_mode : paymentMode
    //     })
    //     this.props.paymentEnable(paymentMode)
    // } 
    render() {
        // let {t} = this.props;
        let reservatonDetail = {};
        if(this.props.guestDetail){
            reservatonDetail =  this.props.guestDetail.guestDetail;
        }

        let reservationData = (localStorage.getItem('reservationParams'))?
                        JSON.parse(localStorage.getItem('reservationParams'))
                        :{};
        let paymentContent = "";
        let hotelDetail = {};
        if (this.props.guest.hotelDetail) {
            hotelDetail = this.props.guest.hotelDetail;
            if(hotelDetail.hotel_locale){
                let language = currentLanguage();
                let localeData = hotelDetail.hotel_locale[language];
                if(localeData){
                    paymentContent = localeData.find(function(assets) {
                        return (assets.tag=="payment_declaration")
                    });
                }
            }
        }
        let paymentDetail = []
        if (this.props.paymentDetail) {
            paymentDetail = this.props.paymentDetail;
        }
        return(
            <div className="process-section-details">
                {(this.props.paymentError)?<div className="error-text">
                    {t("payment_error_or_failed_message")}
                {/* There are an error in completing your payment. It appears to have been cancelled. Please try again. */}
                </div>:""}
                <div className="pay-details-wrapper">
                    <div className="check-image-pay"><img src={check} alt="check icon" /></div>
                    <GuestDetail id={t('amount')} value={reservationData.currency_symbol+reservationData.deposit_amount} />
                </div>

                <div className="payment-text-wrapper">
                    {(paymentContent && paymentContent.text)?<div dangerouslySetInnerHTML={{ __html: paymentContent.text.split("\n").join("<br /><br />")}}>
                    </div>
                    :""}
                </div>
            </div>
        );
    }
}
function mapState(state) {
    return {
        guest:{hotelDetail:state.guest.hotelDetail},
        guestDetail:state.hotelDetail,
        paymentDetail:state.paymentDetail
    };
}

export default connect(mapState)((Pay));