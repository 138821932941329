import React from "react";
import Logo from '../assets/images/logo.png';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import InputText from "../components/input";
import Datepicker from '../components/datepicker';
import SimpleReactValidator from 'simple-react-validator';
import Layout from '../components/layout'
import Shadow from '../assets/images/shadow.svg';
import { guestConstants } from "../_constants";
import Loader from "../components/common/loader";

class Booking extends React.Component {
	constructor(props) {
		super(props);
		this.getBookingDetail = this.getBookingDetail.bind(this);
		this.inputChangeEvent = this.inputChangeEvent.bind(this);
		this.getHotelDetail = this.getHotelDetail.bind(this);
		this.getGuestDetailFailed = this.getGuestDetailFailed.bind(this);
		this.validator = new SimpleReactValidator({	
			messages: {
				required: 'Please enter your :attribute',
				numeric:'Please enter a valid :attribute',
				email:'Please enter a valid :attribute'
			}
		});
		this.state = {bookingParams:{},dataLoad:false,bookingError:false,reservationLoad:false};
	}

	changeLang = (e) => {
		let { i18n } = this.props;
		i18n.changeLanguage(e.target.value);
	};

	componentDidMount() {
		this.props.updateOnRefresh(
			false,
			guestConstants.LOADER
		)
		document.getElementsByTagName("body")[0].classList.add("android_device");
		setTimeout(() => {
			this.setState({
				setAnimation: false
			})
		}, 1000)
		localStorage.removeItem('hotel_code');
		localStorage.removeItem('reservation_code');
		localStorage.removeItem('hotelData');
		localStorage.removeItem('reservationData');
		localStorage.removeItem('reservationParams');
	}

	getBookingDetail(){
		
		if (this.validator.allValid()) {
			this.props.updateOnRefresh(
				true,
				guestConstants.LOADER
			)
			
			let params = this.state.bookingParams;
			this.setState({
				dataLoad:false,
				bookingError:false
			})
			console.log("this.props.getReservationDetail(params)",this.state.dataLoad)
			if(!this.state.dataLoad){
				Promise.all([
					new Promise((resolve,rejact)=>this.props.getReservationDetail(params,resolve,rejact))
				]).then(()=>{
					this.setState({
						reservationLoad:true
					})
				});
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	inputChangeEvent(e){
		let bookingParams = this.state.bookingParams;
		bookingParams[e.target.name] = e.target.value;
		this.setState({
			bookingParams:bookingParams			
		})
	}

	getHotelDetail (hotel_code){
		let params = {hotel_code:hotel_code}
		this.props.getHotelDetail(params);
		this.setState({
			dataLoad:true
		})
	}

	getGuestDetailFailed(){
		this.setState({
			bookingError:true			
		})
	}

	render() {
		
		let guestDetail = {};
		
		if (this.props?.guest?.guestDetailCall=="success" && Object.keys(this.props?.guest?.guestDetail).length>0 && !this.state?.dataLoad && this.state?.reservationLoad) {
			console.log(this.props,"paramsparamsparams")
			guestDetail = this.props.guest.guestDetail;
		
			let params = {hotel_code:guestDetail.hotel_code}
			console.log(params,"paramsparamsparams")
			Promise.all([
				new Promise((resolve,rejact)=>this.props.getHotelDetail(params,resolve,rejact))
			]).then(this.setState({
						reservationLoad:false
					}));			
		}else if(this.props.guest.guestDetailCall=="failed" && !this.state.bookingError){
			this.getGuestDetailFailed();
		}
		
		// console.log(this.props,"this.props",Object.keys(this.props.guest.hotelDetail).length);return true;
		if(Object.keys(this.props.guest.hotelDetail).length>0 && Object.keys(this.props.guest.guestDetail).length>0){
			guestDetail = this.props.guest.guestDetail;
			if(guestDetail.reservation_code!=undefined && guestDetail.hotel_code !=undefined){
				// return <Redirect to={"/?reservation_code="+guestDetail.reservation_code+"&hotel_code="+guestDetail.hotel_code} />
				document.getElementsByTagName("body")[0].classList.remove("android_device");
				this.props.history.push("/?reservation_code="+guestDetail.reservation_code+"&hotel_code="+guestDetail.hotel_code);
			}
		}
		
		console.log('loading booking', this.props.guest.loading);
		return (
			<Layout
				is_outer
				location={this.props.location}
				booking_page={true}>
				{(this.props.guest.loading ? <Loader /> : "")}
				<div className={`splash-screen-full ${this.state.setAnimation ? '' : 'stop-loading'}`}>
					<div>
						<img className="ghost" src={Logo} alt="thru logo" />
						<p className="shadowFrame">
							<img src={Shadow} className="shadow" />
						</p>
					</div>
				</div>
				<div className="  ">
				<div className="initial-screen-content">
					<div className="hotel-logo">
						<img src={Logo} alt="thru logo" />
					</div>
					<div className="initial-screen-hotel-container">
						<div className="hotel-information">
							<div className="hotel-name">
								Enter your booking details
							</div>
							{(this.state.bookingError)?
							<div className="error-text">Booking not found. Please try again.</div>
							:""}
							<div className="booking-details-wrapper">
								<InputText 
									type="text"
									name="booking_id"
									placeholder="Booking ID"
									defaultValue={(this.state.bookingParams.booking_id)?this.state.bookingParams.booking_id:""}
									required={true}
									onChange={this.inputChangeEvent}
									// onBlur={this.validator.showMessageFor('booking_id')}
									helpText={this.validator.message('booking_id', this.state.bookingParams.booking_id, 'required')}
									error={this.validator.message('booking_id', this.state.bookingParams.booking_id, 'required')}
								/>
								<InputText 
									type="text"
									name="last_name"
									placeholder="Last Name" 
									defaultValue={(this.state.bookingParams.last_name)?this.state.bookingParams.last_name:""}
									required={true}
									onChange={this.inputChangeEvent}
									// onBlur={this.validator.showMessageFor('last_name')}
									helpText={this.validator.message('last_name', this.state.bookingParams.last_name, 'required')}
									error={this.validator.message('last_name', this.state.bookingParams.last_name, 'required')}
									/>
								<Datepicker
									name='arrival_date'
									placeholder="Arrival Date"
									disablePast={true}
									value={(this.state.bookingParams.arrival_date)?this.state.bookingParams.arrival_date:null}
									required={true}
									onChange={this.inputChangeEvent}
									// onBlur={this.validator.showMessageFor('arrival_date')}
									helpText={this.validator.message('arrival_date', this.state.bookingParams.arrival_date, 'required')}
									error={this.validator.message('arrival_date', this.state.bookingParams.arrival_date, 'required')}
									/>
							</div>
						</div>

						<div className="hotel-get-started">
							{/* {onClick={() => Router.push('/reservation')}} */}
							<button className="primary-button" onClick={this.getBookingDetail}>
                				Get Started
							</button>
						</div>
					</div>
				</div>
			</div>
			</Layout>
			
		)
	}
}

function mapState(state) {
	return state;
}

const actionCreators = {
	getHotelDetail: guestActions.getHotelDetail,
	getReservationDetail: guestActions.getReservationDetail,
	updateFormData: guestActions.updateFormData,
	updateOnRefresh:guestActions.updateOnRefresh
};

export default connect(mapState, actionCreators)(Booking);