import config from '../app-config';
export const guestService = {
	getHotelDetail,
	getReservationDetail,
	getCountryStateCityData,
	saveGuestProfiles,
	saveGuestIDImages,
	scanDocument,
	paymentSettlement,
	getPaymentDetail,
	intiatePayment,
	updatePaymentStatus,
	sendConfirmationEmail,
	getPaymentStatus,
	getLegalDocuments
};

function getHotelDetail(params) {
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers
	};

	return fetch(`${config.environment.api_baseurl}/getHotelDetail?hotel_code=${params.hotel_code}`, requestOptions)
		.then(handleResponse)
		.then(hotelData => {
			return hotelData;
		});
}


function getReservationDetail(params) {
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers
	}

	let booking_id = (params.booking_id)? "external_booking_id="+params.booking_id : ''; 
	let last_name = (params.last_name)? "&last_name="+params.last_name : ''; 

	let arrival_date = (params.arrival_date)? "&arrival_date="+params.arrival_date : ''; 
	
	let reservation_code = (params.reservation_code)? "reservation_code="+params.reservation_code : ''; 

	return fetch(`${config.environment.api_baseurl}/getReservationDetail?${reservation_code+booking_id+last_name+arrival_date}`, requestOptions)
		.then(handleResponse)
		.then(guestData => {
			return guestData;
		});
}

function getCountryStateCityData() {
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers
	};
	try {
	let	URL = config.environment.api_baseurl + "/getCountryStateCity";
		return fetch(URL, requestOptions)
			.then(handleResponse)
			.then(countryData => {
				return countryData;
			});
	} catch (err) {
		// all errors will be captured here for anything in the try block
		console.log("Error to get country Data")
		console.log(err)
		// return err;
	}
}

function saveGuestProfiles(params) {
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(params)
	}

	return fetch(`${config.environment.api_baseurl}/saveGuestDetail`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function saveGuestIDImages(params) {
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(params)
	}

	return fetch(`${config.environment.api_baseurl}/uploadGuestDocuments`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function paymentSettlement(params) {
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(params)
	}

	return fetch(`${config.environment.api_baseurl}/generateConfirmation?reservation_code=${params.reservation_code}`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function scanDocument(params) {
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(params)
	}

	return fetch(`${config.environment.api_baseurl}/scanDocument`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}


function intiatePayment(params) {
	let tempData = {data:params}
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(tempData)
	}
	//?payment_code=${params.payment_code}&&reservation_code=${params.reservation_code}
	// return fetch(`${config.environment.payment_api_baseurl}/Payment-InitiatePayment`, requestOptions)
	return fetch(`${config.environment.payment_api_baseurl}/InitiatePayment`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function getPaymentDetail(params) {
	
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers,
	}
	return fetch(`${config.environment.payment_api_baseurl}/Payment-PaymentRequest?reservation_code=${params.reservation_code}`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}


function updatePaymentStatus(params) {
	let tempData = {data:params}
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(tempData)
	}
	return fetch(`${config.environment.api_baseurl}/updatePayment`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function sendConfirmationEmail(params) {
	const requestOptions = {
		method: 'POST',
		headers: config.environment.headers,
		body:JSON.stringify(params)
	}
	return fetch(`${config.environment.api_baseurl}/sendConfirmationEmail`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}



function getPaymentStatus(params) {
	
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers,
	}
	// return fetch(`${config.environment.payment_api_baseurl}/Payment-GetInvoiceStatusForGuest?reservation_code=${params.reservation_code}`, requestOptions)
	return fetch(`${config.environment.payment_api_baseurl}/getInvoiceStatusForGuest?reservation_code=${params.reservation_code}`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function getLegalDocuments() {
	
	const requestOptions = {
		method: 'GET',
		headers: config.environment.headers,
	}
	return fetch(`${config.environment.api_baseurl}/legaldocuments/guest_app`, requestOptions)
		.then(handleResponse)
		.then(response => {
			return response;
		});
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				window.location.reload();
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

function logout() {
	// remove user from local storage to log user out
}
