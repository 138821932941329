import React from "react";
import GuestDetail from "../components/guestdetail";
import Layout from "../components/layout";
// import  { withTranslation } from '../components/lang';
import { connect } from 'react-redux';
import { guestActions } from '../_actions';
import MediaQuery from 'react-responsive';
import Header from "../components/header";
import Loader  from "../components/common/loader"
import { guestConstants } from '../_constants';
import { Redirect } from "react-router-dom";
import {t,multiSort, getStorage} from '../utils'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
class Reservation extends React.Component {
	constructor(props){
		super(props);
		this.state = {redirect:""};
	}
	componentDidMount() {
		this.props.updateOnRefresh(
			true,
			guestConstants.LOADER
		)
	}

	guestProfileContinue = () => {
		let propsData = {...this.props};
		propsData.guestProps.guestProfile = [];
		this.props.updateFormData(propsData);
		localStorage.setItem("anonymous_guest",true);
		this.setState({redirect:'/checkindetail'});
	}

	updateGuestDetil = () => {
		let propsData = {...this.props};
		let guestProfiles = propsData.guestDetail.guests_profile;
		let finalData = [];
		localStorage.setItem("anonymous_guest",false);
		if(propsData?.guestProfile?.length>0){
			this.setState({redirect:'/checkindetail'});
		} else {
			guestProfiles = multiSort(guestProfiles,{guest_type:"desc"});
			guestProfiles.map((data, index) => {
				let temp = {
					guest_profile : {},
					guest_custom_details : {},
					guest_document_images:[],
					guest_document:{ id_detail: {}, visa_detail: {} },
					travel_detail:{}
				}
				delete data.guest_custom_details;
				delete data.guest_type;
				let phoneNoData = (data.phone_number)?parsePhoneNumberFromString(data.phone_number):"";
				data.phone_number =(phoneNoData?.nationalNumber) ? phoneNoData.nationalNumber : data.phone_number;
				data.phone_country_code = (phoneNoData?.countryCallingCode) ? "+" + phoneNoData.countryCallingCode : "";
				
				temp.guest_profile = data;
				if(index==0){
					let reservationParams = JSON.parse(localStorage.getItem("reservationParams"));
					if(data?.email_address){
						reservationParams.email_address = data.email_address;
					}
				}
				
				finalData.push(temp);
			})
			propsData.guestProps.guestProfile = finalData;
			this.props.updateFormData(propsData);
			this.setState({redirect:'/checkindetail'});
		}
	}

	render() {
		let guestDetail = getStorage("reservationData","object",{})
		console.log(this.props.guestDetail,"this.props.guestDetail")
		let guest_name = "";
		let guestParams = getStorage("reservationParams","object",{});
		if (Object.keys(guestParams).length>0 || Object.keys(guestDetail).length>0) {
			guestDetail = (Object.keys(guestDetail).length>0)?guestDetail:guestParams;
			guest_name = ((guestDetail.title) ? guestDetail.title + ' ' : "") +
				((guestDetail.re_first_name) ? guestDetail.re_first_name + ' ' : guestDetail.first_name+" ") +
				((guestDetail.re_last_name) ? guestDetail.re_last_name : guestDetail.last_name);
		}

		if (this.state.redirect) {
			// return <Redirect to={this.state.redirect}/>
			this.props.history.push(this.state.redirect);
		}

		return (
			<React.Fragment>
			{(this.props.loading?<Loader/>:"")}
			<Layout location={this.props.location}>
				
				<div className={`reservation-screen-wrapper ${(guestDetail.guest_status=="checkin_pending" && guestDetail?.guests_profile?.length>0) ? 'multiple': ''}`}>
					<div className="reservation-detail">
						<MediaQuery minDeviceWidth={500}>
							<Header />
						</MediaQuery>
						<h2>{t('reservation')}</h2>
						<h4>
							<span>{t('booking_id')} </span>
							<span>{(guestDetail.external_booking_id) ? guestDetail.external_booking_id : 'NA'}</span>
						</h4>
						<div className="guest-info">
							<GuestDetail id={t('guest')} value={guest_name} />
							<div className="guest-2column">
								<GuestDetail id={t('arrival')} value={(guestDetail.arrival_date) ? guestDetail.arrival_date : 'NA'} />
								<GuestDetail id={t('departure')} value={(guestDetail.departure_date) ? guestDetail.departure_date : 'NA'} />
							</div>
							<GuestDetail id={t('room_type')} value={(guestDetail.room_type) ? guestDetail.room_type : 'NA'} />
							{/* <GuestDetail id={t('billing_instructions')} value="N/A" /> */}
							<GuestDetail id={t('room_tariff')} value={(guestDetail.room_rate) ? guestDetail.currency_symbol+guestDetail.room_rate : 'NA'} />
							{/* <GuestDetail id={t('meal_plan')} value="N/A" /> */}
							<div className="guest-2column">
								<GuestDetail id={t('adults')} value={(guestDetail.no_of_adult) ? guestDetail.no_of_adult : 0} />
								<GuestDetail id={t('kids')} value={(guestDetail.no_of_child) ? guestDetail.no_of_child : 0} />
							</div>
						</div>
					</div>
					

					{(guestDetail.guest_status=="checkin_pending" && guestDetail.guests_profile.length>0)?
						<div className="footer-btn footer-2-btns">
							<div className="footer-backdrop-default" />
								<button
									className="secondary-button option-display"
									onClick={this.guestProfileContinue}>
									<span>{t('anonymous_guest_btn')}</span>
									<span> <i className="arrow right"></i></span>
								</button>
								<button
									className='primary-button option-display'
									onClick={this.updateGuestDetil}>									
									<span>{t('continue')}</span>
									<span><i className="arrow right"></i></span>
								</button>
						</div>
						:
						<div className="footer-btn">
							<div className="footer-backdrop-default" />
							<button className="primary-button option-display" onClick={() => this.setState({redirect:'/checkindetail'})}>
								<span>{t('continue')}</span>
								<span><i className="arrow right"></i></span>
							</button>
						</div>
					}
				</div>
			</Layout>
		</React.Fragment>
		);
	}
}

function mapState(state) {
	return {
		guestDetail:state.guest.guestDetail,
		guestProps:state.guest,
		loading:state.loading
	};
}

const actionCreators = {
	updateFormData: guestActions.updateFormData,
	updateOnRefresh: guestActions.updateOnRefresh
};
export default connect(mapState, actionCreators)((Reservation));